"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowProfileUser = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var React = __importStar(require("react"));
var Profile_1 = require("./Profile");
var UserBase_1 = require("./UserBase");
var util_1 = require("../util");
var UserProfileTag_1 = require("./UserProfileTag");
exports.ShowProfileUser = React.memo(function (state) { return ((0, jsx_runtime_1.jsx)(UserBase_1.UserBase, { user: state.user, world: false, main: React.useMemo(function () { return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(Profile_1.Profile, { profile: state.user.profile, bio: state.user.bio, bioLinks: state.user.bioLinks }, void 0), (0, jsx_runtime_1.jsx)(UserProfileTag_1.UserProfileTag, { userProfile: state.user.profile, searchTags: [] }, void 0)] }, void 0)); }, [state.user]) }, void 0)); }, function (prev, next) {
    if (!(0, util_1.isUserEqual)(prev.user, next.user))
        return false;
    if (!(0, util_1.isWorldShallowEqual)(prev.world, next.world))
        return false;
    if (prev.worldLoading !== next.worldLoading)
        return false;
    return true;
});
