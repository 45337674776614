"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteWorldSearchParam = exports.saveWorldSearchParam = exports.fetchWorldSearchParams = exports.fetchWorldDescription = exports.fetchWorldProfiles = exports.fetchPublicWorldsByPage = exports.fetchPublicWorlds = exports.sendInviteMe = exports.fetchWorldInstance = exports.fetchMyWorlds = exports.fetchWorld = exports.fetchWorlds = void 0;
var fetch_1 = require("./fetch");
var base_1 = require("./base");
var fetchWorlds = function (ids) { return __awaiter(void 0, void 0, void 0, function () {
    var url, uniqIdsHash, _i, ids_1, id, useIds, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!ids.length)
                    return [2 /*return*/, []];
                url = new URL(base_1.origin + "/api/vrcapi/worlds/many");
                uniqIdsHash = {};
                for (_i = 0, ids_1 = ids; _i < ids_1.length; _i++) {
                    id = ids_1[_i];
                    uniqIdsHash[id] = true;
                }
                useIds = Object.keys(uniqIdsHash);
                return [4 /*yield*/, (0, fetch_1.post)(url.href, { body: new URLSearchParams({ ids: useIds.join(" ") }) })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch worlds error")];
            case 2: // idsが長すぎるので
            return [2 /*return*/, (_a.sent()).worlds];
        }
    });
}); };
exports.fetchWorlds = fetchWorlds;
var fetchWorld = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/vrcapi/world/" + id);
                return [4 /*yield*/, (0, fetch_1.get)(url.href)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch world error")];
            case 2: return [2 /*return*/, (_a.sent()).world];
        }
    });
}); };
exports.fetchWorld = fetchWorld;
var fetchMyWorlds = function () { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/vrcapi/worlds/me");
                return [4 /*yield*/, (0, fetch_1.get)(url.href)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch self worlds error")];
            case 2: return [2 /*return*/, (_a.sent()).worlds];
        }
    });
}); };
exports.fetchMyWorlds = fetchMyWorlds;
var fetchWorldInstance = function (worldId, instanceFullId) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response, worldInstance;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/vrcapi/world/" + worldId + "/" + instanceFullId);
                return [4 /*yield*/, (0, fetch_1.get)(url.href)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch world instance error")];
            case 2:
                worldInstance = (_a.sent()).worldInstance;
                worldInstance.fetchedAt = new Date();
                return [2 /*return*/, worldInstance];
        }
    });
}); };
exports.fetchWorldInstance = fetchWorldInstance;
var sendInviteMe = function (worldAndInstanceId) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/vrcapi/instances/" + worldAndInstanceId + "/invite");
                return [4 /*yield*/, (0, fetch_1.post)(url.href)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "invite me error")];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
exports.sendInviteMe = sendInviteMe;
var fetchPublicWorlds = function () { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/vrcapi/worlds/public");
                return [4 /*yield*/, (0, fetch_1.get)(url.href)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch worlds error")];
            case 2: return [2 /*return*/, (_a.sent()).worlds];
        }
    });
}); };
exports.fetchPublicWorlds = fetchPublicWorlds;
var fetchPublicWorldsByPage = function (page) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/vrcapi/worlds/public");
                url.searchParams.set("page", page.toString());
                return [4 /*yield*/, (0, fetch_1.get)(url.href)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch worlds error")];
            case 2: return [2 /*return*/, (_a.sent()).worlds];
        }
    });
}); };
exports.fetchPublicWorldsByPage = fetchPublicWorldsByPage;
var fetchWorldProfiles = function (ids) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, []];
}); }); };
exports.fetchWorldProfiles = fetchWorldProfiles;
/*
    if (!ids.length) return [];
    const url = new URL(`${origin}/api/world_profiles/many`);
    const uniqIdsHash: { [id: string]: true } = {};
    for (const id of ids) uniqIdsHash[id] = true;
    const useIds = Object.keys(uniqIdsHash);
    const response = await post(url.href, { body: new URLSearchParams({ ids: useIds.join(" ") }) }); // idsが長すぎるので
    const { worldProfiles } = (await extractJsonOrThrow(
        response,
        "fetch world profiles error"
    )) as IWorldProfilesApiResponse;
    return worldProfiles;
    */
var fetchWorldDescription = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/world_descriptions/" + id);
                return [4 /*yield*/, (0, fetch_1.get)(url.href)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch world descriptions error")];
            case 2: return [2 /*return*/, (_a.sent())
                    .descriptions];
        }
    });
}); };
exports.fetchWorldDescription = fetchWorldDescription;
function fetchWorldSearchParams() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, fetch_1.get)(base_1.origin + "/api/world_search_params")];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch search params error")];
                case 2: return [2 /*return*/, (_a.sent())
                        .worldSearchParams];
            }
        });
    });
}
exports.fetchWorldSearchParams = fetchWorldSearchParams;
function saveWorldSearchParam(name, content) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, fetch_1.post)(base_1.origin + "/api/world_search_params/" + encodeURIComponent(name), {
                        body: JSON.stringify(content),
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "save search params error")];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.saveWorldSearchParam = saveWorldSearchParam;
function deleteWorldSearchParam(name) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, fetch_1._delete)(base_1.origin + "/api/world_search_params/" + encodeURIComponent(name))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "delete search params error")];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.deleteWorldSearchParam = deleteWorldSearchParam;
