"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditableProfileTag = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var EditProfileTag_1 = require("./EditProfileTag");
var EditProfileTagButton_1 = require("./EditProfileTagButton");
var UserProfileTag_1 = require("./UserProfileTag");
var withState_1 = require("../hocs/withState");
var connect = (0, withState_1.withState)()(["editTags", "searchParams", "setSearchTags", "lang"]);
function isEditableProfileTagPropsEqual(prev, next) {
    if (next.editTags === next.user.id)
        return false; // 編集中は常に再描画
    if (prev.lang !== next.lang)
        return false;
    if (prev.editTags !== next.editTags)
        return false;
    if (prev.searchParams.tags !== next.searchParams.tags)
        return false;
    if (prev.user.id !== next.user.id)
        return false;
    if ((prev.user.profile || {}).tags !== (next.user.profile || {}).tags)
        return false;
    if ((prev.user.profile || {}).lockTags !== (next.user.profile || {}).lockTags)
        return false;
    return true;
}
exports.EditableProfileTag = connect.memoFC(function (state) {
    return state.editTags === state.user.id ? ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(EditProfileTag_1.EditProfileTag, __assign({}, state), void 0) }, void 0)) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(UserProfileTag_1.UserProfileTag, { userProfile: state.user.profile, searchTags: state.searchParams.tags, setSearchTags: state.setSearchTags }, void 0), (0, jsx_runtime_1.jsx)(EditProfileTagButton_1.EditProfileTagButton, { besideTags: true, user: state.user }, void 0)] }, void 0));
}, isEditableProfileTagPropsEqual);
