"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFirstGetFriends = void 0;
var react_1 = require("react");
function useFirstGetFriends(state) {
    (0, react_1.useEffect)(function () {
        if (!state.friendsFirstFetched && !state.friendsLoading && !state.friendsFetchRestInterval) {
            state.setFriendsLoadingState("start");
        }
    }, []);
}
exports.useFirstGetFriends = useFirstGetFriends;
