"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toJPISODateString = void 0;
var zeropad_1 = require("./zeropad");
var serverTimezoneOffset = new Date().getTimezoneOffset();
var fixTimezoneOffset = (serverTimezoneOffset + 9 * 60) * 60 * 1000;
function toJPISODateString(date) {
    var tzDate = new Date(date.getTime() + fixTimezoneOffset);
    return tzDate.getFullYear() + "-" + (0, zeropad_1.zeropad)(tzDate.getMonth() + 1, 2) + "-" + (0, zeropad_1.zeropad)(tzDate.getDate(), 2);
}
exports.toJPISODateString = toJPISODateString;
