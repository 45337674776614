"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetContentsSeries = void 0;
var react_1 = require("react");
var sentry_1 = require("../sentry");
var contents_1 = require("../api/contents");
function useGetContentsSeries(state) {
    (0, react_1.useEffect)(function () {
        if (state.contentsSeriesFetchRequest && !state.contentsSeriesLoading) {
            state.setContentsSeriesFetchRequest(false);
            state.setContentsSeriesLoading(true);
            (0, contents_1.fetchContentsSeries)().then(function (contentsSeriesList) {
                state.setContentsSeriesList(contentsSeriesList);
                state.setContentsSeriesLoading(false);
            }, function (error) {
                (0, sentry_1.captureException)(error);
                state.setContentsSeriesLoading(false);
            });
        }
    }, [state.contentsSeriesFetchRequest]);
}
exports.useGetContentsSeries = useGetContentsSeries;
