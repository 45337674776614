"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subPlaceOrders = exports.subPlaces = void 0;
exports.subPlaces = {
    u: { title: "Users", color: "#06f", path: "/users" },
    a: { title: "Avatars", color: "#e33", path: "/avatars" },
    w: { title: "Worlds", color: "#888", path: "/worlds" },
    // w: { title: "Worlds", color: "#0a3", path: "/worlds" },
    g: { title: "Groups", color: "#906", path: "/groups" },
    c: { title: "Contents", color: "#0a3", path: "/contents" },
    e: { title: "Events", color: "#e80", path: "/events" },
    k: { title: "Keywords", color: "#888", path: "/keywords" },
};
exports.subPlaceOrders = [
    "u",
    // "a",
    "g",
    "c",
    "w",
    // "e",
    // "k",
];
