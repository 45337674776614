"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.withState = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var React = __importStar(require("react"));
var context_1 = require("../context");
var util_1 = require("../util");
function withState() {
    return function connect(stateKeys, mapProps) {
        return {
            /** AllState が注入されたFC */
            FC: function (Component) {
                return function WithStateComponent(props) {
                    var allState = React.useContext(context_1.AllStateContext);
                    var newProps = __assign(__assign({}, (0, util_1.pick)(allState || {}, stateKeys)), props);
                    var mappedNewProps = mapProps ? mapProps(newProps) : newProps;
                    return (0, jsx_runtime_1.jsx)(Component, __assign({}, mappedNewProps), void 0);
                };
            },
            /** AllState が注入されたFC memoつき */
            memoFC: function (Component, propsAreEqual) {
                var MemoComponent = React.memo(function MemoComponent(_props) {
                    return (0, jsx_runtime_1.jsx)(Component, __assign({}, _props), void 0);
                }, propsAreEqual);
                return function WithStateMemoComponent(props) {
                    var allState = React.useContext(context_1.AllStateContext);
                    var newProps = __assign(__assign({}, (0, util_1.pick)(allState || {}, stateKeys)), props);
                    var mappedNewProps = mapProps ? mapProps(newProps) : newProps;
                    return (0, jsx_runtime_1.jsx)(MemoComponent, __assign({}, mappedNewProps), void 0);
                };
            },
            /** AllStateから抽出するキー */
            injectedPropKeys: function () {
                return stateKeys;
            },
            /** 外に露出するProps 型解釈用なので有効な値は返らない */
            ExportProps: function () {
                return {};
            },
            /** 注入されたProps 型解釈用なので有効な値は返らない */
            InjectedProps: function () {
                return {};
            },
            /** 全てのProps 型解釈用なので有効な値は返らない */
            AllProps: function () {
                // for types
                return {};
            },
        };
    };
}
exports.withState = withState;
