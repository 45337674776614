"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteTitle = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var react_router_1 = require("react-router");
var SubPlace_1 = require("../models/SubPlace");
var dropdownOptions = SubPlace_1.subPlaceOrders.map(function (subPlaceId) { return ({
    key: subPlaceId,
    text: ((0, jsx_runtime_1.jsxs)("span", __assign({ className: "site-title-subtitle", style: { color: SubPlace_1.subPlaces[subPlaceId].color } }, { children: [SubPlace_1.subPlaces[subPlaceId].title, "\u00A0"] }), void 0)),
    value: subPlaceId,
    className: "site-title-subtitle-select",
}); });
exports.SiteTitle = (0, react_router_1.withRouter)((0, react_1.memo)(function SiteTitle(_a) {
    var size = _a.size, selector = _a.selector, subPlaceId = _a.subPlaceId, location = _a.location, history = _a.history;
    if (!subPlaceId)
        subPlaceId = location.pathname[1];
    var subtitle = SubPlace_1.subPlaces[subPlaceId];
    return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Header, __assign({ as: "h1", className: "site-title", style: { display: "inline-block" }, size: size }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: "site-title-vr" }, { children: "VR" }), void 0), (0, jsx_runtime_1.jsx)("span", __assign({ className: "site-title-chat" }, { children: "Chat" }), void 0), "\u00A0", subtitle &&
                (selector !== false ? ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Dropdown, { as: "span", icon: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}, void 0), inline: true, defaultValue: subPlaceId, options: dropdownOptions, onChange: function (e, _a) {
                        var value = _a.value;
                        if (value === subPlaceId)
                            return;
                        // eslint-disable-next-line @typescript-eslint/no-shadow
                        var subtitle = SubPlace_1.subPlaces[value];
                        if (subtitle)
                            history.push(subtitle.path);
                    } }, void 0)) : ((0, jsx_runtime_1.jsxs)("span", __assign({ className: "site-title-subtitle", style: { color: subtitle.color } }, { children: [subtitle.title, "\u00A0"] }), void 0))), subtitle ? ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Responsive, __assign({ as: "span", minWidth: 480 }, { children: (0, jsx_runtime_1.jsx)("span", __assign({ className: "site-title-profile" }, { children: "Profile" }), void 0) }), void 0)) : ((0, jsx_runtime_1.jsx)("span", __assign({ className: "site-title-profile" }, { children: "Profile" }), void 0))] }), void 0));
}, function (prev, next) { return prev.size === next.size && prev.location.pathname === next.location.pathname; }));
