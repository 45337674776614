"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.platforms = exports.Platform = void 0;
var Platform;
(function (Platform) {
    Platform["standalonewindows"] = "standalonewindows";
    Platform["android"] = "android";
})(Platform = exports.Platform || (exports.Platform = {}));
exports.platforms = Array.from(Object.entries(Platform)).map(function (_a) {
    var p = _a[0];
    return p;
});
