"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Contact = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var semantic_ui_react_1 = require("semantic-ui-react");
var react_router_dom_1 = require("react-router-dom");
exports.Contact = (0, react_router_dom_1.withRouter)(function (_a) {
    var history = _a.history;
    return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Segment, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Grid, __assign({ columns: "2" }, { children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Grid.Column, { children: ["Contact:", " ", (0, jsx_runtime_1.jsx)("a", __assign({ href: "https://twitter.com/narazaka", target: "_blank", rel: "noopener noreferrer" }, { children: "Twitter @narazaka" }), void 0)] }, void 0), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, __assign({ textAlign: "right" }, { children: (0, jsx_runtime_1.jsx)("a", __assign({ href: "/", onClick: function (e) {
                            e.preventDefault();
                            history.push("/", { gotoTop: true });
                        } }, { children: "TOP" }), void 0) }), void 0)] }), void 0) }, void 0));
});
