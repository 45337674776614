"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.componentWorldToWorlds = exports.AsWithWorldsPropKeys = exports.WithWorldsPropKeys = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var util_1 = require("../util");
var omitKeys = { world: true, worldLoading: true };
exports.WithWorldsPropKeys = ["worlds", "needWorldIds", "missingWorldIds"];
function AsWithWorldsPropKeys(keys) {
    return keys.filter(function (key) { return !omitKeys[key]; }).concat(exports.WithWorldsPropKeys);
}
exports.AsWithWorldsPropKeys = AsWithWorldsPropKeys;
function componentWorldToWorlds(Component) {
    return function ComponentWorldToWorldsComponent(props) {
        var worldId = props.user.locationInfo.worldId;
        var world = props.worlds === false ? false : props.worlds[worldId];
        var worldLoading = props.worlds === false ? false : (0, util_1.isWorldLoading)(worldId, props);
        return ((0, jsx_runtime_1.jsx)(Component, __assign({}, __assign(__assign({}, props), { world: world, worldLoading: worldLoading })), void 0));
    };
}
exports.componentWorldToWorlds = componentWorldToWorlds;
