"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
exports.default = {
    reportErrorTitle: "Is it a bug?",
    reportErrorMessage1: "Please feel free to reply to ",
    reportErrorMessage2: " if you think there is a possible bug in the VRChat Profile.",
    reportErrorIgnore: "No problem",
    reportErrorAccept: "Report a bug",
    vmMessageTitle: "Virtual Market 3 is in session!",
    vmMessageContent: "VRChat Profile supports Virtual Market 3.",
    omMessageTitle: "ALTER-NATIVE Market (RAKUSEN MARKET) is in session!",
    omMessageContent: "VRChat Profile supports ALTER-NATIVE Market (RAKUSEN MARKET).",
    vfMessageTitle: "Virtual Frontier is in session!",
    vfMessageContent: "VRChat Profile supports Virtual Frontier.",
    vrcUnstableMessageTitle: "The friends list will be unstable due to VRChat's load countermeasures.",
    vrcUnstableMessageContent: 'It seems that VRChat is intentionally restricting friend acquisition to prevent load. As a result, the online status and the "Instances" tab may not work properly. We apologize for any inconvenience this may cause.',
    vrcUnavailableMessageTitle: "Temporarily unavailable due to changes in VRChat API",
    vrcUnavailableMessageContent: "Due to changes in the VRChat API, this service is currently unavailable. We are investigating this issue, but it may take some time to resolve.",
    messageTitle: "Server maintenance with downtime scheduled!",
    messageContent: "VRChat Profile will be unavailable for several minutes to several tens of minutes from 2019/3/7 13:30 today.\nWe apologize for any inconvenience, but please be forewarned.",
    isUnsupportedBrowserMessageTitle: "Unsupported browser",
    isOldEdgeMessageContent: "It is not compatible with Microsoft Edge before Windows 10 October 2018 Update is applied (some functions do not work). Please use other browsers.",
    siteDescriptionTitle: "About",
    siteDescription: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["VRChat Profile is a tool to complement VRChat's lack of social functions and make VRChat more convenient.", (0, jsx_runtime_1.jsx)("br", {}, void 0), "It was originally made to take notes so that I could remember my VRChat friends, but it has been expanded and useful.", (0, jsx_runtime_1.jsx)("br", {}, void 0), "If you have any feature requests or suggestions for improvement, please feel free to contact us at", " ", (0, jsx_runtime_1.jsx)("a", __assign({ href: "https://twitter.com/narazaka", target: "_blank", rel: "noopener noreferrer" }, { children: "@narazaka (Twitter)" }), void 0), "."] }, void 0)),
    usersDescription: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["You can see the profile of VRChat friend, and you can make private memos of the friend's profile.", (0, jsx_runtime_1.jsx)("br", {}, void 0), "Register your profile to improve searchability! (Thanks.", (0, jsx_runtime_1.jsx)("br", {}, void 0), "You can also see a list of instances where your friends are now. Convenient!"] }, void 0)),
    groupsDescription: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["You can register \u201Cgroups\u201D in VRChat.", (0, jsx_runtime_1.jsx)("br", {}, void 0), "In addition to the so-called \u201CXX Force\u201D, you may register personal groups."] }, void 0)),
    contentsDescription: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Share your VRChat related creations!", (0, jsx_runtime_1.jsx)("br", {}, void 0), "You can link VRChat comics or pictures depicting VRChat friends with the friends you are drawing.", (0, jsx_runtime_1.jsx)("br", {}, void 0), "You can register tweets now."] }, void 0)),
    descriptionCaution: "*This tool is using the VRChat API but irrelevant to VRChat Team.",
    aboutTitle: "About",
    forDevelopers: "For developers",
    privacyPolicy: "Privacy Policy",
    i18nContribute: "Translation cooperator",
    profileCount: "Count of profile",
    memoUserCount: "Count of user who made private memo",
    sampleTitleAprilFool: "Pressure is strong (Sample profile)",
    sampleTitle: "Author (Sample profile)",
    sampleProfilePrefix: "↓★★★ Let's make a profile! ★★★\n\n",
    sampleTagPrefix: "★★★ Tags enabled ★★★",
    samplePrivateMemo: "↓★★ Can write personal memos. ★★\n\nlow-poly\nponytail avatar",
    samplePrivateMemoAprilFool: "↓★★ Can write personal memos. ★★\n\npsychic monster",
    sampleLoadError: "Failed to load sample profile. Prease check your network and reload this page.",
    username: "username",
    usernameNotice: "username for login",
    password: "password",
    twoFactorAuthCode: "Two-Factor Auth code",
    twoFactorAuthDescription: "Enter a numeric code from your authenticator app.",
    loginNotice: "The password will be sent to the server only for VRChat user authentication and will not be stored on the server.\n" +
        "(There is currently no way to technically authenticate a user other than sending a password.)\n" +
        "(Waiting for the VRChat API to implement OAuth)\n" +
        '"Keep logged in" keeps encrypted session cookies that can not guess passwords.',
    loginNoticeNoExtension: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["In", " ", (0, jsx_runtime_1.jsx)("a", __assign({ href: "https://www.google.com/chrome/", target: "_blank", rel: "noopener noreferrer" }, { children: "Google Chrome (PC)" }), void 0), ",", " ", (0, jsx_runtime_1.jsx)("a", __assign({ href: "https://www.mozilla.org/firefox/", target: "_blank", rel: "noopener noreferrer" }, { children: "Firefox (PC)" }), void 0), false && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [",", " ", (0, jsx_runtime_1.jsx)("a", __assign({ href: "https://play.google.com/store/apps/details?id=org.mozilla.firefox", target: "_blank", rel: "noopener noreferrer" }, { children: "Firefox Mobile (Android)" }), void 0)] }, void 0)), " ", "etc., it is possible to log in to this site without directly entering the password by the extension function.", (0, jsx_runtime_1.jsx)("br", {}, void 0)] }, void 0)),
    loginBrowserRecommend: "We recommend using these compatible browsers.",
    loginNoticeWithExtension: "The extension allows you to log in using the vrchat.com session cookie.\n" +
        "(Password cannot be guessed or changed from session cookie.)\n" +
        "(There is currently no way to technically authenticate a user other than sending a session cookie.)\n" +
        "(Waiting for the VRChat API to implement OAuth)\n" +
        '"Keep logged in" keeps encrypted session cookies that can not guess passwords.',
    loginCautionTitle: "Risk of entering account information",
    loginCaution: "VRChat Profile is a service operated by individuals unrelated to VRChat.\n" +
        "Entering a VRChat account information will give the operator of the service the right to perform all operations on the account, and the vulnerability of the service will affect the account." +
        "(VRChat Profile does not perform unauthorized account operations according to the privacy policy, but it is technically impossible to prove whether it is really complied with or not.)\n" +
        "In consideration of this risk, please use this service at your own risk only when you can trust the operator. (This is a problem with the existing VRChat authentication method itself, so be careful with other VRChat related services as well)",
    installExtension: "Install the extension",
    loginWithExtension: "Login with vrchat.com",
    loginFallbackToNoExtension: "Login with password",
    login: "Login",
    logout: "Logout",
    loginfailed: "Login failed!",
    keepLoggedIn: "Keep logged in",
    missingCredentialsLogout: "Your login session has expired.\nYou need to log in again.\nDo you want to go to the login screen now?",
    missingCredentialsLogoutWithExtension: "Could not log in.\nPlease log out at https://vrchat.com/home.",
    getFriends: "Fetch friends",
    noUsers: "found no users",
    friends: "Friends",
    nonFriendUser: "Non-friend user",
    my: "My",
    world: "World",
    instances: "Instances",
    getDetails: "Get details",
    platform: "Platform",
    search: "Search",
    searchByAccount: "Search by account",
    saveSearchParams: "Save search params",
    searchParamsName: "Search param name",
    profile: "Profile",
    twitter: "Twitter",
    steam: "Steam",
    discord: "Discord",
    discordInviteUrl: "Discord invite URL",
    discordInviteUrlShouldNotExpire: "We recommend that you do not set an expiration date for the invitation URL.",
    booth: "Booth",
    vroidhub: "VRoid Hub",
    vroidhubDescription: 'The part of /users/(this numbers) of the "Your characters" page URL',
    github: "GitHub",
    gitlab: "Gitlab",
    youtube: "YouTube",
    niconico: "niconico",
    joinedAt: "Joined at",
    ambiguousToBlank: "Leave the ambiguous place blank.",
    birthday: "Birthday",
    friendAt: "The day you became a friend",
    friendAtWithCaution: "The day you became a friend (only for some users)",
    site: "Website",
    tags: "Tags",
    tagsDescription: "public tags",
    lockTags: "Lock tags",
    privateMemo: "Memo(Private)",
    privateMemoDescription: "private memo",
    permission: "Instance Permission",
    advancedSearch: "Advanced options",
    haveProfile: "Have profile",
    havePrivateMemo: "Have private memo",
    showOnlyUserExistsTab: "Hide tabs with zero people",
    syncPrivateMemo: "Sync memos (private) to VRChat.com",
    syncingPrivateMemo: "Memos (private) are now syncing to VRChat.com...",
    syncPrivateMemoDescription: "Synchronize memos (private) edited by this service to VRChat.com's note data.",
    nosyncPrivateMemo: "Not yet synchronized memos",
    syncPrivateMemoByThis: "Synchronize the memo with the above",
    syncPrivateMemoTooLong: "Too long to auto-sync.",
    syncPrivateMemoMustManual: "There are memos that are not synced to VRChat.com, but cannot be automatically synced.",
    compactView: "Compact view",
    sortByInstance: "Sort by instance",
    any: "Any",
    exists: "Yes",
    notExists: "No",
    show: "Show",
    hide: "Hide",
    page: "Page",
    perPage: "per page",
    cancel: "Cancel",
    reset: "Reset",
    edit: "Edit",
    editName: "Edit name",
    editUsers: "Edit users",
    create: "Create",
    update: "Update",
    delete: "Delete",
    saved: "Saved",
    save: "Save",
    completeEditUsers: "Finish editing users",
    add: "Add",
    back: "Back",
    cancelConfirm: "Are you sure you want to cancel, although changes will be lost?",
    deleteConfirm: "Do you really want to delete this?",
    required: "Required",
    overMaxLength: "Too long.",
    loading: "Loading",
    saving: "Saving",
    image: "Image",
    imageMaxSize: "Up to 500KB",
    imageType: "PNG / GIF / JPEG",
    dropOrChooseFile: "Drop file or click to select file",
    showProfileNotFound: "This user never got in VRchat Profile.",
    showProfileDescription: "Let's publish your profile with VRChat Profile!",
    share: "Share",
    twitterText: "Let's meet at VRChat!",
    max: "Max",
    min: "Min",
    year: "Year",
    month: "Month",
    date: "Date",
    minuteAgo: "minute ago",
    minutesAgo: "minutes ago",
    overOneHourAgo: "More than an hour ago",
    lastTime: "Last time",
    createdAt: "Created at",
    updatedAt: "Updated at",
    createUserDefinedGroupHelp: 'Create a new "favorite group" (only on VRChat Profile)',
    addUserDefinedGroupUserHelp: "Add the user to the editing favorites group",
    removeUserDefinedGroupUserHelp: "Remove the user from the editing favorites group",
    addUserDefinedGroupPrimaryUserHelp: "Make the user featured in the editing favorites group",
    removeUserDefinedGroupPrimaryUserHelp: "Make the user not featured in the editing favorites group",
    group: "Group",
    createGroup: "Create a new group",
    getGroups: "Fetch groups",
    noGroups: "Group not found",
    groupName: "Group name",
    groupSummary: "Catchphrase (70 characters)",
    groupProfile: "Profile",
    groupCreatedAt: "Created at",
    groupTag: "Group member tag",
    joined: "Joined",
    contents: "Works",
    createContents: "Register your work",
    noContents: "No work found",
    contentsSeriesName: "Name (series name, etc.)",
    worldInstanceDetailDisabledCaution: "Get details are currently unavailable due to changes in the VRChat API specification.",
    worldName: "World Name",
    description: "Description",
    authorName: "Author Name",
    capacity: "Capacity",
    public: "Public",
    communityLabs: "Labs",
    private: "Private",
    publicStatus: "Public Status",
    publicationDate: "Published",
    labsPublicationDate: "Labs'd",
    favorites: "Favorites",
    heat: "HEAT",
    visits: "Visits",
};
