"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetWorldDetail = void 0;
var react_1 = require("react");
var util_1 = require("../util");
function useGetWorldDetail(state) {
    (0, react_1.useEffect)(function () {
        if (state.requestDetailWorld) {
            if ((0, util_1.isWorldNeedDetailFetch)(state.requestDetailWorld)) {
                state.dispatchMissingWorldIds({ operation: "push", ids: [state.requestDetailWorld.id] });
                state.setRequestDetailWorld(undefined);
            }
        }
    }, [state.requestDetailWorld]);
}
exports.useGetWorldDetail = useGetWorldDetail;
