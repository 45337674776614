"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Users = exports.pickupColor = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var semantic_ui_react_1 = require("semantic-ui-react");
var User_1 = require("./User");
var util_1 = require("../util");
var componentWorldToWorlds_1 = require("../hocs/componentWorldToWorlds");
var PagenationBySize_1 = require("./PagenationBySize");
var UsersMenu_1 = require("./UsersMenu");
var withState_1 = require("../hocs/withState");
var PagenationMenu_1 = require("./PagenationMenu");
var UserByWorlds = (0, componentWorldToWorlds_1.componentWorldToWorlds)(User_1.User);
exports.pickupColor = "yellow";
exports.Users = (0, withState_1.withState)()(componentWorldToWorlds_1.WithWorldsPropKeys).FC(function Users(state) {
    var users = (0, PagenationBySize_1.pagenate)(state.users, state.page, state.pageSize);
    if (!users.length)
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}, void 0);
    var userState = __assign({}, (state.showWorlds !== false
        ? (0, util_1.pick)(state, componentWorldToWorlds_1.WithWorldsPropKeys)
        : { worlds: {}, needWorldIds: [], missingWorldIds: [] }));
    var menuState = (0, util_1.pick)(state, PagenationMenu_1.PagePropKeys);
    var isPickupId = {};
    if (state.pickupIds) {
        var pickupIds = state.pickupIds;
        if (pickupIds instanceof Array) {
            for (var i = 0; i < pickupIds.length; ++i)
                isPickupId[pickupIds[i]] = true;
        }
        else {
            isPickupId = pickupIds;
        }
    }
    return state.compactView ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [state.menu !== false && (0, jsx_runtime_1.jsx)(UsersMenu_1.UsersMenu, __assign({}, menuState, { length: state.users.length }), void 0), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Group, __assign({ stackable: true, doubling: true }, { children: users.map(function (user) { return ((0, jsx_runtime_1.jsx)(UserByWorlds, __assign({ user: user, color: isPickupId[user.id] ? exports.pickupColor : undefined }, userState, { compactView: true }), user.id)); }) }), void 0), state.menu !== false && (0, jsx_runtime_1.jsx)(UsersMenu_1.UsersMenu, __assign({}, menuState, { length: state.users.length }), void 0)] }, void 0)) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [state.menu !== false && (0, jsx_runtime_1.jsx)(UsersMenu_1.UsersMenu, __assign({}, menuState, { length: state.users.length }), void 0), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid, __assign({ doubling: true, stackable: true, columns: state.columns || 4 }, { children: users.map(function (user) { return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(UserByWorlds, __assign({ user: user, color: isPickupId[user.id] ? exports.pickupColor : undefined }, userState), void 0) }, user.id)); }) }), void 0), state.menu !== false && (0, jsx_runtime_1.jsx)(UsersMenu_1.UsersMenu, __assign({}, menuState, { length: state.users.length }), void 0)] }, void 0));
});
