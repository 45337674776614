"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSyncPrivateMemos = exports.findPrivateMemoSyncTarget = void 0;
var react_1 = require("react");
var api_1 = require("../api");
var util_1 = require("../util");
var waitInterval = 4000;
var findPrivateMemoSyncTarget = function (_a) {
    var onlineFriends = _a.onlineFriends, offlineFriends = _a.offlineFriends, favoriteFriends = _a.favoriteFriends;
    var target = onlineFriends.find(util_1.isNotPrivateMemoSyncedAndCanAutoSync);
    if (target)
        return target;
    target = offlineFriends.find(util_1.isNotPrivateMemoSyncedAndCanAutoSync);
    if (target)
        return target;
    for (var _i = 0, favoriteFriends_1 = favoriteFriends; _i < favoriteFriends_1.length; _i++) {
        var ums = favoriteFriends_1[_i];
        target = ums.find(util_1.isNotPrivateMemoSyncedAndCanAutoSync);
        if (target)
            return target;
    }
    return target;
};
exports.findPrivateMemoSyncTarget = findPrivateMemoSyncTarget;
// syncではなくmigrate
function useSyncPrivateMemos(_a) {
    var syncPrivateMemo = _a.syncPrivateMemo, setSyncPrivateMemo = _a.setSyncPrivateMemo, onlineFriends = _a.onlineFriends, offlineFriends = _a.offlineFriends, favoriteFriends = _a.favoriteFriends, setOnlineFriends = _a.setOnlineFriends, setOfflineFriends = _a.setOfflineFriends, setFavoriteFriends = _a.setFavoriteFriends, syncingPrivateMemoUserModel = _a.syncingPrivateMemoUserModel, setSyncingPrivateMemoUserModel = _a.setSyncingPrivateMemoUserModel;
    (0, react_1.useEffect)(function () {
        if (!syncPrivateMemo)
            return;
        if (syncingPrivateMemoUserModel)
            return;
        var target = (0, exports.findPrivateMemoSyncTarget)({ onlineFriends: onlineFriends, offlineFriends: offlineFriends, favoriteFriends: favoriteFriends });
        if (!target) {
            setSyncPrivateMemo(false);
            return;
        }
        // VRCP=>VRCなのでnoteはかかない
        setSyncingPrivateMemoUserModel({ userId: target.id, synced: false, wait: true });
    }, [syncPrivateMemo, syncingPrivateMemoUserModel, onlineFriends, offlineFriends, favoriteFriends]);
    (0, react_1.useEffect)(function () {
        if (!syncingPrivateMemoUserModel)
            return;
        var userId = syncingPrivateMemoUserModel.userId, note = syncingPrivateMemoUserModel.note, synced = syncingPrivateMemoUserModel.synced, wait = syncingPrivateMemoUserModel.wait;
        // 同期後user書き換え
        if (synced) {
            var _a = (0, util_1.applyToUserMany)({ onlineFriends: onlineFriends, offlineFriends: offlineFriends, favoriteFriends: favoriteFriends }), replaceById = _a.replaceById, apply = _a.apply;
            replaceById(userId, function (um) {
                var _a;
                return note
                    ? __assign(__assign({}, um), { note: note, profile: __assign(__assign({}, um.profile), { id: um.id, privateMemo: note, privateMemoIsSynced: true }) }) : __assign(__assign({}, um), { note: ((_a = um.profile) === null || _a === void 0 ? void 0 : _a.privateMemo) || "", profile: __assign(__assign({}, um.profile), { id: um.id, privateMemoIsSynced: true }) });
            });
            apply({
                onlineFriends: onlineFriends,
                offlineFriends: offlineFriends,
                favoriteFriends: favoriteFriends,
                setOnlineFriends: setOnlineFriends,
                setOfflineFriends: setOfflineFriends,
                setFavoriteFriends: setFavoriteFriends,
            });
            setSyncingPrivateMemoUserModel(undefined);
            return;
        }
        // 同期を実行
        var succeed = function () {
            setSyncingPrivateMemoUserModel({ userId: userId, note: note, synced: true });
        };
        // エラーでもスルー
        var errored = function () {
            setSyncingPrivateMemoUserModel(undefined);
        };
        if (note) {
            (0, util_1.waitMs)(wait ? waitInterval : 1)
                .then(function () { return (0, api_1.migrateProfilePrivateMemoByNote)(userId, note); })
                .then(succeed, errored);
        }
        else {
            (0, util_1.waitMs)(wait ? waitInterval : 1)
                .then(function () { return (0, api_1.migrateProfilePrivateMemo)(userId); })
                .then(succeed, errored);
        }
    }, [syncingPrivateMemoUserModel]);
}
exports.useSyncPrivateMemos = useSyncPrivateMemos;
