"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
exports.default = {
    reportErrorTitle: "버그가 발생했나요?",
    reportErrorMessage1: "VRChat Profile에 버그가 있다고 생각되는 경우, ",
    reportErrorMessage2: "로 언제든지 문의 바랍니다.",
    reportErrorIgnore: "괜찮습니다",
    reportErrorAccept: "버그 보고",
    vmMessageTitle: "Virtual Market 3 개최 중!",
    vmMessageContent: "VRChat Profile은 Virtual Market 3를 응원합니다.",
    omMessageTitle: "낙선마켓 개최 중!",
    omMessageContent: "VRChat Profile은 낙선마켓을 응원합니다.",
    vfMessageTitle: "Virtual Frontier 개최중!",
    vfMessageContent: "VRChat Profile은 Virtual Frontier를 응원합니다.",
    vrcUnstableMessageTitle: "The friends list will be unstable due to VRChat's load countermeasures.",
    vrcUnstableMessageContent: 'It seems that VRChat is intentionally restricting friend acquisition to prevent load. As a result, the online status and the "Instances" tab may not work properly. We apologize for any inconvenience this may cause.',
    vrcUnavailableMessageTitle: "Temporarily unavailable due to changes in VRChat API",
    vrcUnavailableMessageContent: "Due to changes in the VRChat API, this service is currently unavailable. We are investigating this issue, but it may take some time to resolve.",
    messageTitle: "서버 점검이 있을 예정입니다(접속 불가)",
    messageContent: "오늘 2019/3/7 13:30부터 수 분 ~ 수 시간 동안 VRChat Profile의 이용이 중단됩니다.\n 양해해 주시기 바랍니다.",
    isUnsupportedBrowserMessageTitle: "지원하지 않는 브라우저입니다",
    isOldEdgeMessageContent: "Windows 10 2018년 10월 업데이트 이전의 Microsoft Edge에서는 지원하지 않습니다(일부 기능이 동작하지 않을 수 있음). 다른 브라우저를 이용 바랍니다.",
    siteDescriptionTitle: "VRChat 소셜을 더 편리하게!",
    siteDescription: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["VRChat Profile\uC740 VRChat\uC758 \uBD80\uC871\uD55C \uC18C\uC15C \uAE30\uB2A5\uC744 \uBCF4\uC644\uD558\uACE0 VRChat\uC744 \uB354\uC6B1 \uD3B8\uB9AC\uD558\uAC8C \uC990\uAE30\uAE30 \uC704\uD55C \uB3C4\uAD6C\uC785\uB2C8\uB2E4.", (0, jsx_runtime_1.jsx)("br", {}, void 0), "\uCC98\uC74C\uC5D0\uB294 VRChat \uCE5C\uAD6C \uBAA8\uB450\uB97C \uB204\uAC00 \uB204\uAD6C\uC778\uC9C0 \uAE30\uC5B5\uD560 \uC218 \uC5C6\uC5B4\uC11C \uBA54\uBAA8\uD558\uAE30 \uC704\uD574 \uB9CC\uB4E4\uC5C8\uC9C0\uB9CC, \uC774\uB7F0\uC800\uB7F0 \uAE30\uB2A5\uC774 \uCD94\uAC00\uB418\uC5B4 \uD3B8\uB9AC\uD574\uC84C\uC2B5\uB2C8\uB2E4.", (0, jsx_runtime_1.jsx)("br", {}, void 0), "\uAE30\uB2A5 \uC694\uCCAD\uC774\uB098 \uAC1C\uC120\uC744 \uC704\uD55C \uC81C\uC548\uC774 \uC788\uC73C\uC2DC\uBA74 \uBD80\uB514", " ", (0, jsx_runtime_1.jsx)("a", __assign({ href: "https://twitter.com/narazaka", target: "_blank", rel: "noopener noreferrer" }, { children: "@narazaka (Twitter)" }), void 0), "\uB85C \uC5B8\uC81C\uB4E0 \uBB38\uC758 \uBC14\uB78D\uB2C8\uB2E4."] }, void 0)),
    usersDescription: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["VRChat \uCE5C\uAD6C\uC758 \uD504\uB85C\uD544\uC744 \uBCFC \uC218 \uC788\uC73C\uBA70, \uC5B4\uB5A4 \uC0AC\uB78C\uC778\uC9C0 \uAC1C\uC778 \uBA54\uBAA8\uB97C \uC791\uC131\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.", (0, jsx_runtime_1.jsx)("br", {}, void 0), "\uC790\uC2E0\uC758 \uD504\uB85C\uD544\uC744 \uB4F1\uB85D\uD574 \uAC80\uC0C9\uC131\uC744 \uB192\uC5EC\uBD05\uC2DC\uB2E4! (\uB9C9\uB358\uC9D0", (0, jsx_runtime_1.jsx)("br", {}, void 0), "\uB610, \uCE5C\uAD6C\uAC00 \uB4E4\uC5B4\uAC00 \uC788\uB294 \uC778\uC2A4\uD134\uC2A4 \uBAA9\uB85D\uB3C4 \uBCFC \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uD3B8\uD574\uC694!"] }, void 0)),
    groupsDescription: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["VRChat\uC758 \uADF8\uB8F9 \uB4F1 \uB2E8\uCCB4\uB97C \uB4F1\uB85D\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.", (0, jsx_runtime_1.jsx)("br", {}, void 0), "\uCEE4\uB2E4\uB780 \uADF8\uB8F9 \uC774\uC678\uC5D0\uB3C4 \uB514\uC2A4\uCF54\uB4DC\uBC29 \uAC19\uC740 \uAC1C\uC778\uC801\uC778 \uADF8\uB8F9\uB3C4 \uB4F1\uB85D\uD574\uB450\uBA74 \uC88B\uC744\uC9C0\uB3C4?"] }, void 0)),
    contentsDescription: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["VRChat \uAD00\uB828 \uCC3D\uC791\uBB3C\uC744 \uACF5\uC720\uD558\uC790!", (0, jsx_runtime_1.jsx)("br", {}, void 0), "VRChat \uCE5C\uAD6C\uB4E4\uC744 \uADF8\uB9B0 \uB9CC\uD654\uB098 \uC77C\uB7EC\uC2A4\uD2B8\uC640, \uADF8\uB824\uC838\uC788\uB294 \uCE5C\uAD6C\uB4E4\uC774 \uC5F0\uACB0\uB429\uB2C8\uB2E4.", (0, jsx_runtime_1.jsx)("br", {}, void 0), "\uD604\uC7AC Twitter\uB97C \uC9C0\uC6D0\uD569\uB2C8\uB2E4."] }, void 0)),
    descriptionCaution: "※이 툴은 VRChat API를 사용하고 있지만 공식 VRChat과는 직접적인 관련이 없습니다.",
    aboutTitle: "사이트 소개",
    forDevelopers: "개발자분들께",
    privacyPolicy: "개인정보처리방침",
    i18nContribute: "번역 협력",
    profileCount: "프로필 등록 수",
    memoUserCount: "개인메모 사용자 수",
    sampleTitleAprilFool: "미친 존재감 (프로필 샘플)",
    sampleTitle: "제작자 (프로필 샘플)",
    sampleProfilePrefix: "↓★★★프로필을 만들어보자!★★★\n\n",
    sampleTagPrefix: "★★★태그도 붙여보자★★★",
    samplePrivateMemo: "↓★★★개인 메모도 쓸 수 있습니다★★★\n\n리본 포니테일 덧니 안경 세라복",
    samplePrivateMemoAprilFool: "↓★★★개인 메모도 쓸 수 있습니다★★★\n\n요괴",
    sampleLoadError: "프로필 샘플을 불러오지 못했습니다. 네트워크 설정을 확인하고 페이지를 다시 표시하십시오.",
    username: "유저명",
    usernameNotice: "로그인 시의 유저명",
    password: "암호",
    twoFactorAuthCode: "이중 인증 코드",
    twoFactorAuthDescription: "인증 앱에 표시되는 보안 코드를 입력하십시오",
    loginNotice: "비밀번호는 VRChat 유저 인증을 위해서만 서버로 전송되며 저장되지 않습니다. (https로 암호화되어 송신됩니다)\n" +
        "(원래는 트위터 인증 같은 걸로 하고 싶지만, 서버에 암호를 보내는 것 외에는 현재 기술적으로 사용자를 인증할 방법이 없어서...)\n" +
        "(VRChat API에 OAuth같은게 들어가면 좋은데) \n" +
        '"로그인 상태 유지"는 암호를 추측할 수 없는 암호화 된 세션 쿠키를 이용합니다.',
    loginNoticeNoExtension: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["In", " ", (0, jsx_runtime_1.jsx)("a", __assign({ href: "https://www.google.com/chrome/", target: "_blank", rel: "noopener noreferrer" }, { children: "Google Chrome (PC)" }), void 0), ",", " ", (0, jsx_runtime_1.jsx)("a", __assign({ href: "https://www.mozilla.org/firefox/", target: "_blank", rel: "noopener noreferrer" }, { children: "Firefox (PC)" }), void 0), false && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [",", " ", (0, jsx_runtime_1.jsx)("a", __assign({ href: "https://play.google.com/store/apps/details?id=org.mozilla.firefox", target: "_blank", rel: "noopener noreferrer" }, { children: "Firefox Mobile (Android)" }), void 0)] }, void 0)), " ", "\uB4F1\uC5D0\uC11C\uB294 \uD655\uC7A5\uAE30\uB2A5\uC744 \uD1B5\uD574 \uC9C1\uC811 \uD328\uC2A4\uC6CC\uB4DC\uB97C \uC785\uB825\uD558\uC9C0 \uC54A\uACE0\uB3C4 \uBCF8 \uC0AC\uC774\uD2B8\uC5D0 \uB85C\uADF8\uC778 \uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.", (0, jsx_runtime_1.jsx)("br", {}, void 0)] }, void 0)),
    loginBrowserRecommend: "다음과 같은 지원 브라우저를 사용하는 것을 권합니다.",
    loginNoticeWithExtension: "확장기능을 통해 vrchat.com의 세션쿠키를 사용해 로그인할 수 있습니다.\n" +
        "(세션쿠키정보를 통해 비밀번호를 추측, 변경하는 것은 불가능합니다)\n" +
        "(사실은 Twitter 인증같은 식으로 하고싶었으나, 세션정보를 보내는 방법 외에 본인인증을 할 방법이 없어서...)\n" +
        "(VRChat API에 OAuth같은 기능이 지원되면 좋은데)\n" +
        '"로그인 유지" 는 비밀번호를 추측불가능하게 암호화한 세션쿠키를 저장합니다.',
    loginCautionTitle: "계정 정보 입력의 위험성에 대해",
    loginCaution: "VRChat Profile은 VRChat와 관련이 없는 개인이 운영하는 서비스입니다.\n" +
        "VRChat ID 및 암호를 입력하면 본 서비스의 운영자가 계정의 모든 작업을 수행 할 수 있는 권한을 부여받게 되며, 본 서비스의 취약성이 계정에 영향을 미칠 수 있다는 뜻입니다." +
        "(VRChat Profile은 개인정보처리방침에 따라 허가되지 않은 계정 작업을 수행하지 않지만, 실제로 준수되고 있는지 증명하는 것은 기술적으로 불가능합니다.)\n" +
        "이 위험성을 고려하여, 운영자를 신뢰할 수 있을 때만 이 서비스를 사용하십시오. (이것은 현행 VRChat 인증 방법 자체의 문제이므로, 다른 VRChat 관련 서비스에서도 마찬가지로 주의해야 합니다)",
    installExtension: "확장기능 설치",
    loginWithExtension: "vrchat.com으로 로그인",
    loginFallbackToNoExtension: "기존 방식으로 로그인",
    login: "로그인",
    logout: "로그아웃",
    loginfailed: "로그인 실패",
    keepLoggedIn: "로그인 상태 유지",
    missingCredentialsLogout: "로그인 세션이 만료되었습니다.\n다시 로그인해 주세요.\n지금 로그인 페이지로 이동하시겠습니까?",
    missingCredentialsLogoutWithExtension: "로그인에 실패했습니다. \n다시 한 번 https://vrchat.com/home에서 로그아웃해 주세요.",
    getFriends: "친구정보 불러오기",
    noUsers: "유저를 찾을 수 없습니다",
    friends: "친구",
    nonFriendUser: "친구가 아닌 유저",
    my: "My",
    world: "월드",
    instances: "인스턴스",
    getDetails: "상세정보 불러오기",
    platform: "플랫폼",
    search: "검색",
    searchByAccount: "계정으로 검색",
    saveSearchParams: "검색옵션 저장",
    searchParamsName: "검색옵션 이름",
    profile: "프로필",
    twitter: "Twitter",
    steam: "Steam",
    discord: "Discord",
    discordInviteUrl: "Discord 초대 링크",
    discordInviteUrlShouldNotExpire: "초대 링크의 만료 날짜를 설정하지 않는 것이 좋습니다",
    booth: "Booth",
    vroidhub: "VRoid Hub",
    vroidhubDescription: '"Your characters" 페이지 URL의 /users/(이 숫자 부분)',
    github: "GitHub",
    gitlab: "Gitlab",
    youtube: "YouTube",
    niconico: "niconico",
    joinedAt: "시작한 날",
    ambiguousToBlank: "애매한 곳은 공란으로 두세요.",
    birthday: "생일",
    friendAt: "친구가 된 날",
    friendAtWithCaution: "친구가 된 날 (일부 유저만 해당)",
    site: "웹 사이트",
    tags: "태그",
    tagsDescription: "모두가 볼 수 있는 태그",
    lockTags: "태그 잠금",
    privateMemo: "메모 (비공개)",
    privateMemoDescription: "개인적인 메모",
    permission: "인스턴스 상태",
    advancedSearch: "상세 옵션",
    haveProfile: "프로필 있음",
    havePrivateMemo: "메모(비공개)의 유무",
    showOnlyUserExistsTab: "Hide tabs with zero people",
    syncPrivateMemo: "Sync memos (private) to VRChat.com",
    syncingPrivateMemo: "Memos (private) are now syncing to VRChat.com...",
    syncPrivateMemoDescription: "Synchronize memos (private) edited by this service to VRChat.com's note data.",
    nosyncPrivateMemo: "Not yet synchronized memos",
    syncPrivateMemoByThis: "Synchronize the memo with the above",
    syncPrivateMemoTooLong: "Too long to auto-sync.",
    syncPrivateMemoMustManual: "There are memos that are not synced to VRChat.com, but cannot be automatically synced.",
    compactView: "작게 보기",
    sortByInstance: "Sort by instance",
    any: "모두",
    exists: "있음",
    notExists: "없음",
    show: "보이기",
    hide: "숨기기",
    page: "페이지",
    perPage: "개 씩",
    cancel: "취소",
    reset: "재설정",
    edit: "편집",
    editName: "이름 변경",
    editUsers: "유저 변경",
    create: "만들기",
    update: "업데이트",
    delete: "삭제",
    add: "추가",
    saved: "저장됨",
    save: "저장",
    completeEditUsers: "유저 변경 완료",
    back: "뒤로",
    cancelConfirm: "모든 변경 사항이 삭제됩니다. 취소하시겠습니까?",
    deleteConfirm: "정말로 삭제하시겠습니까?",
    required: "필수",
    overMaxLength: "너무 깁니다",
    loading: "로드 중",
    saving: "저장 중",
    image: "이미지",
    imageMaxSize: "최대 500KB",
    imageType: "PNG / GIF / JPEG",
    dropOrChooseFile: "파일을 끌어서 놓기 또는 클릭하여 파일 선택",
    showProfileNotFound: "이 유저는 VRchat Profile에서 검색된 적이 없습니다.",
    showProfileDescription: "VRChat Profile로 프로필을 공유합시다!",
    share: "공유",
    twitterText: "VRChat에서 만나자!",
    max: "Max",
    min: "Min",
    year: "년",
    month: "월",
    date: "일",
    minuteAgo: "분 전",
    minutesAgo: "분 전",
    overOneHourAgo: "1시간 이상",
    lastTime: "지난번",
    createdAt: "Created at",
    updatedAt: "Updated at",
    createUserDefinedGroupHelp: '새로운 "즐겨찾기 그룹"을 만듭니다. (VRChat Profile 상에서만)',
    addUserDefinedGroupUserHelp: "수정중인 즐겨찾기 그룹에 유저를 추가합니다.",
    removeUserDefinedGroupUserHelp: "수정중인 즐겨찾기 그룹에서 유저를 삭제합니다.",
    addUserDefinedGroupPrimaryUserHelp: "편집중인 즐겨찾기 그룹의 주목유저로 설정합니다.",
    removeUserDefinedGroupPrimaryUserHelp: "편집중인 즐겨찾기 그룹의 주목유저에서 해제합니다.",
    group: "그룹",
    createGroup: "그룹을 새로 만들기",
    getGroups: "그룹정보 불러오기",
    noGroups: "그룹을 찾을 수 없습니다",
    groupName: "그룹명",
    groupSummary: "캐치프레이즈 (70자)",
    groupProfile: "그룹 프로필",
    groupCreatedAt: "설립일",
    groupTag: "그룹 멤버 태그",
    joined: "소속됨",
    contents: "작품",
    createContents: "작품 등록",
    noContents: "작품을 찾을 수 없습니다",
    contentsSeriesName: "이름 (시리즈 이름 등)",
    worldInstanceDetailDisabledCaution: "상세정보는 현재 VRChat API 사양의 변경으로 인해 사용할 수 없습니다.",
    worldName: "월드명",
    description: "설명",
    authorName: "제작자",
    capacity: "최대인원",
    public: "Public",
    communityLabs: "Labs",
    private: "Private",
    publicStatus: "공개상태",
    publicationDate: "Public 공개일",
    labsPublicationDate: "Labs 공개일",
    favorites: "즐겨찾기수",
    heat: "HEAT",
    visits: "방문자수",
};
