"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorldStatus = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var React = __importStar(require("react"));
var semantic_ui_react_1 = require("semantic-ui-react");
var ILocationInfo_1 = require("../models/ILocationInfo");
var WorldInstanceSummary_1 = require("./WorldInstanceSummary");
var WorldStatusIcon_1 = require("./WorldStatusIcon");
exports.WorldStatus = React.memo(function (props) {
    var permission = props.permission;
    if (permission === "offline" || permission === "none") {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}, void 0);
    }
    if (ILocationInfo_1.isJoinablePermission[permission]) {
        return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Popup, { on: "click", position: "bottom center", trigger: (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(WorldStatusIcon_1.WorldStatusIcon, __assign({}, props), void 0) }, void 0), content: (0, jsx_runtime_1.jsx)(WorldInstanceSummary_1.WorldInstanceSummary, __assign({}, props), void 0) }, void 0));
    }
    return (0, jsx_runtime_1.jsx)(WorldStatusIcon_1.WorldStatusIcon, __assign({}, props), void 0);
});
