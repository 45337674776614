"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.asRouteMenuItem = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var asRouteMenuItem = function (location, history) { return function (Component) {
    return function RouteMenuItem(props) {
        var exact = props.exact, path = props.path, menuProps = __rest(props, ["exact", "path"]);
        return ((0, jsx_runtime_1.jsx)(Component, __assign({ active: exact ? location.pathname === path : location.pathname.startsWith(path), onClick: function () { return history.push(path); } }, menuProps), void 0));
    };
}; };
exports.asRouteMenuItem = asRouteMenuItem;
