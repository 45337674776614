"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PagenationMenu = exports.PagePropKeys = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var semantic_ui_react_1 = require("semantic-ui-react");
var PagenationBySize_1 = require("./PagenationBySize");
var i18n_1 = require("../i18n");
exports.PagePropKeys = ["page", "pageSize", "setPage", "setPageSize"];
var PagenationMenu = function (state) { return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Menu, __assign({ stackable: true }, { children: [state.pageSize && state.length ? ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Menu.Item, { children: (0, jsx_runtime_1.jsx)(PagenationBySize_1.PagenationBySize, { page: state.page, setPage: state.setPage, pageSize: state.pageSize, length: state.length, lang: (0, i18n_1.lang)() }, void 0) }, void 0)) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}, void 0)), state.pageSize && state.setPageSize ? ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Menu.Item, { children: (0, jsx_runtime_1.jsx)(PagenationBySize_1.PageSizeSelect, { pageSize: state.pageSize, setPageSize: state.setPageSize, lang: (0, i18n_1.lang)() }, void 0) }, void 0)) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}, void 0)), state.children] }), void 0)); };
exports.PagenationMenu = PagenationMenu;
