"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetPlayerModerations = void 0;
var react_1 = require("react");
var api_1 = require("../api");
var sentry_1 = require("../sentry");
function useGetPlayerModerations(state) {
    var _a = (0, react_1.useState)(false), loading = _a[0], setLoading = _a[1];
    (0, react_1.useEffect)(function () {
        if (state.playerModerationsFetchRequest && state.logined && !loading) {
            state.setPlayerModerationsFetchRequest(false);
            setLoading(true);
            (0, api_1.fetchPlayerModerations)().then(function (playerModerations) {
                var playerModerationsMap = {};
                for (var _i = 0, playerModerations_1 = playerModerations; _i < playerModerations_1.length; _i++) {
                    var playerModeration = playerModerations_1[_i];
                    if (playerModeration.type !== "unmute")
                        continue;
                    playerModeration.createdTime = new Date(playerModeration.created);
                    var id = playerModeration.targetUserId;
                    var type = playerModeration.type;
                    var typeMap = playerModerationsMap[id] || (playerModerationsMap[id] = {});
                    var list = typeMap[type] || (typeMap[type] = []);
                    list.push(playerModeration);
                    if (list.length > 1)
                        list.sort(function (a, b) { return a.createdTime.getTime() - b.createdTime.getTime(); });
                }
                state.setPlayerModerations(playerModerationsMap);
                setLoading(false);
            }, function (error) {
                (0, sentry_1.captureException)(error);
                setLoading(false);
            });
        }
    }, [state.playerModerationsFetchRequest]);
}
exports.useGetPlayerModerations = useGetPlayerModerations;
