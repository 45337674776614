"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserProfileTag = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ProfileTag_1 = require("./ProfileTag");
var UserProfileTag = function (_a) {
    var userProfile = _a.userProfile, searchTags = _a.searchTags, setSearchTags = _a.setSearchTags;
    return userProfile && userProfile.tags && userProfile.tags.length ? ((0, jsx_runtime_1.jsx)(ProfileTag_1.ProfileTag, { tags: userProfile.tags.map(function (tag) { return ({ name: tag.name }); }), searchTags: searchTags, setSearchTags: setSearchTags }, void 0)) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}, void 0));
};
exports.UserProfileTag = UserProfileTag;
