"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserGroups = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var semantic_ui_react_1 = require("semantic-ui-react");
var GroupSummary_1 = require("./GroupSummary");
var util_1 = require("../util");
var withState_1 = require("../hocs/withState");
exports.UserGroups = (0, withState_1.withState)()(["groups"], function (props) { return ({
    groups: props.groups,
    tags: props.user.profile ? props.user.profile.tags : undefined,
}); }).memoFC(function UserGroups(_a) {
    var tags = _a.tags, groups = _a.groups;
    if (!groups || !tags || !tags.length)
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}, void 0);
    var tagExists = (0, util_1.toExistHash)(tags.map(function (tag) { return tag.name; }));
    var joinedGroups = groups.filter(function (group) { return tagExists[group.tagName]; });
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: joinedGroups.map(function (group) { return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Popup, { on: "click", position: "bottom center", trigger: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Label, __assign({ basic: true, as: "a" }, { children: group.name }), void 0), content: (0, jsx_runtime_1.jsx)(GroupSummary_1.GroupSummary, { group: group }, void 0) }, group.id)); }) }, void 0));
});
