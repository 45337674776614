"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SentryErrorBoundary = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var sentry_1 = require("../sentry");
var SentryErrorBoundary = /** @class */ (function (_super) {
    __extends(SentryErrorBoundary, _super);
    function SentryErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { error: null };
        return _this;
    }
    SentryErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        if (!sentry_1.useSentry)
            return;
        this.setState({ error: error });
        sentry_1.Sentry.withScope(function (scope) {
            for (var _i = 0, _a = Object.keys(errorInfo); _i < _a.length; _i++) {
                var key = _a[_i];
                scope.setExtra(key, errorInfo[key]);
            }
            sentry_1.Sentry.captureException(error);
        });
    };
    SentryErrorBoundary.prototype.render = function () {
        if (this.state.error) {
            // render fallback UI
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid
            return (0, jsx_runtime_1.jsx)("a", __assign({ onClick: function () { return sentry_1.Sentry.showReportDialog(); } }, { children: "Report feedback" }), void 0);
        }
        // when there's not an error, render children untouched
        return this.props.children;
    };
    return SentryErrorBoundary;
}(react_1.Component));
exports.SentryErrorBoundary = SentryErrorBoundary;
