"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetUserDefinedGroups = void 0;
var react_1 = require("react");
var sentry_1 = require("../sentry");
var api_1 = require("../api");
function useGetUserDefinedGroups(state) {
    (0, react_1.useEffect)(function () {
        if (state.userDefinedGroupsFetchRequest) {
            state.setUserDefinedGroupsFetchRequest(false);
            (0, api_1.fetchUserDefinedGroups)().then(function (groups) {
                state.setUserDefinedGroups(groups);
                state.backupDb.openDb().then(function (db) {
                    db.putUserDefinedGroups(groups);
                }); // 待たない
            }, function (error) {
                (0, sentry_1.captureException)(error);
            });
        }
    }, [state.userDefinedGroupsFetchRequest]);
}
exports.useGetUserDefinedGroups = useGetUserDefinedGroups;
