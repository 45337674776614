"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vrcMemoIsSame = exports.addVrcpDate = exports.removeVrcpDate = exports.vrcpToVrc = exports.vrcNoteCanonical = void 0;
var toJPISODateString_1 = require("./toJPISODateString");
var vrcChars = {
    ":": "˸",
    "/": "⁄",
    ".": "․",
    "(": "（",
    ")": "）",
    "#": "＃",
    "+": "＋",
    "@": "＠",
    "!": "ǃ",
    "=": "＝",
    '"': "＂",
    "*": "∗",
    ",": "‚",
    ";": ";",
};
var vrcCharsRe = new RegExp("[" + Object.keys(vrcChars).join("") + "]", "g");
var vrcNoteCanonical = function (note) { return (0, exports.removeVrcpDate)(note).replace(/[ \n]+/g, "\0").trim(); };
exports.vrcNoteCanonical = vrcNoteCanonical;
var vrcpToVrc = function (memo) {
    return memo
        .trim()
        .replace(/\r?\n/g, " ")
        // eslint-disable-next-line no-irregular-whitespace
        .replace(/[ 　]+/g, "\0")
        .replace(/[⤴→↑←↓…♥]/g, "")
        .replace(/s\s*h\s*i\s*t/g, "poop")
        .replace(vrcCharsRe, function (c) { return vrcChars[c]; });
};
exports.vrcpToVrc = vrcpToVrc;
var removeVrcpDate = function (note) {
    return note.replace(/\s?C\d{4}-\d{2}-\d{2}(?:U\d{4}-\d{2}-\d{2})?$/, "");
};
exports.removeVrcpDate = removeVrcpDate;
var addVrcpDate = function (_a) {
    var memo = _a.memo, createdAt = _a.createdAt, updatedAt = _a.updatedAt;
    var createdAtStr = (0, toJPISODateString_1.toJPISODateString)(createdAt);
    var updatedAtStr = (0, toJPISODateString_1.toJPISODateString)(updatedAt);
    memo += " C" + createdAtStr;
    if (createdAtStr !== updatedAtStr) {
        memo += "U" + updatedAtStr;
    }
    return memo;
};
exports.addVrcpDate = addVrcpDate;
var vrcMemoIsSame = function (vrcNote, vrcpMemo) {
    if (!vrcNote || !vrcpMemo)
        return !vrcNote && !vrcpMemo;
    return (0, exports.vrcNoteCanonical)(vrcNote) === (0, exports.vrcpToVrc)(vrcpMemo);
};
exports.vrcMemoIsSame = vrcMemoIsSame;
