"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFirstGetContents = void 0;
var react_1 = require("react");
function useFirstGetContents(state) {
    (0, react_1.useEffect)(function () {
        if (!state.contentsSeriesFirstFetched && !state.contentsSeriesLoading) {
            state.setContentsSeriesFetchRequest(true);
            state.setContentsSeriesFirstFetched(true);
        }
        if (!state.contentsItemsFirstFetched && !state.contentsItemsLoading) {
            state.setContentsItemsFetchRequest(true);
            state.setContentsItemsFirstFetched(true);
        }
    }, []);
}
exports.useFirstGetContents = useFirstGetContents;
