"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchTags = void 0;
var react_1 = require("react");
var api_1 = require("../api");
var sentry_1 = require("../sentry");
function needFetchTags(previousFetchedAt) {
    return !previousFetchedAt || new Date().getTime() - previousFetchedAt.getTime() >= 60000;
}
function useFetchTags(state) {
    var _a = (0, react_1.useState)([]), newTags = _a[0], setNewTags = _a[1];
    (0, react_1.useEffect)(function () {
        if (!state.fetchTagsState)
            return;
        // eslint-disable-next-line default-case
        switch (state.fetchTagsState) {
            case "request":
                if (needFetchTags(state.tagsFetchedAt)) {
                    state.setFetchTagsState("fetch");
                }
                else {
                    state.setFetchTagsState(undefined);
                }
                break;
            case "forceRequest":
                state.setFetchTagsState("fetch");
                break;
            case "fetch":
                state.setTagLoading(true);
                (0, api_1.fetchTags)().then(function (tags) {
                    setNewTags(tags || []);
                    state.setTagLoading(false);
                    state.setTagsFetchedAt(new Date());
                    state.setFetchTagsState("set");
                }, function (e) {
                    state.incrementErrorCount();
                    (0, sentry_1.captureException)(e);
                    state.setTagLoading(false);
                    state.setFetchTagsState(undefined);
                });
                break;
            case "set":
                // searchTagsが表示されないのにフィルターがかかる問題回避
                // eslint-disable-next-line no-case-declarations
                var tagsHash_1 = {};
                for (var i = 0; i < newTags.length; ++i)
                    tagsHash_1[newTags[i].name] = true;
                state.setSearchParams({
                    name: "tags",
                    value: (state.searchParams.tags || []).filter(function (name) { return tagsHash_1[name]; }),
                });
                state.setTags(newTags);
                state.setFetchTagsState(undefined);
                break;
        }
    }, [state.fetchTagsState]);
}
exports.useFetchTags = useFetchTags;
