"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagsInput = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var withState_1 = require("../hocs/withState");
var util_1 = require("../util");
exports.TagsInput = (0, withState_1.withState)()(["tags", "tagLoading", "searchParams", "setSearchTags", "requestFetchTags"], function (props) { return (__assign(__assign({}, (0, util_1.omit)(props, ["searchParams"])), { searchTags: props.searchParams.tags || [] })); }).memoFC(function (state) {
    (0, react_1.useEffect)(function () {
        state.requestFetchTags();
    }, []);
    return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Dropdown, { multiple: true, fluid: true, search: true, selection: true, clearable: true, value: state.searchTags, loading: state.tagLoading, options: state.tags.map(function (tag) { return ({ text: tag.name, value: tag.name }); }), onFocus: function () { return state.requestFetchTags(); }, onChange: function (_, _a) {
            var value = _a.value;
            return state.setSearchTags(value);
        } }, void 0));
});
