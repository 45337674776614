"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FriendTabMenuItem = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var semantic_ui_react_1 = require("semantic-ui-react");
var labelColor = function (_a) {
    var hasPrimary = _a.hasPrimary, hasPrimaryJoinable = _a.hasPrimaryJoinable, size = _a.size;
    if (hasPrimaryJoinable)
        return { color: "red" };
    if (hasPrimary)
        return { color: "orange" };
    if (size === 0)
        return { style: { opacity: "0.5" } };
    return {};
};
var FriendTabMenuItem = function (_a) {
    var icon = _a.icon, title = _a.title, size = _a.size, maxSize = _a.maxSize, hasPrimary = _a.hasPrimary, hasPrimaryJoinable = _a.hasPrimaryJoinable, color = _a.color;
    var conditionProps = labelColor({ hasPrimaryJoinable: hasPrimaryJoinable, hasPrimary: hasPrimary, size: size });
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: icon, color: color, style: conditionProps.style }, void 0), (0, jsx_runtime_1.jsx)("span", __assign({ style: __assign(__assign({}, conditionProps.style), { color: color ? "#21ba45" : undefined }) }, { children: title }), void 0), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Label, __assign({}, conditionProps, { children: [size, maxSize && false && "+"] }), void 0)] }, void 0));
};
exports.FriendTabMenuItem = FriendTabMenuItem;
