"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrustMark = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var React = __importStar(require("react"));
var semantic_ui_react_1 = require("semantic-ui-react");
var util_1 = require("../util");
// cf. https://greasyfork.org/en/scripts/376747-vrchat-littleone
var trustTags = [
    { tag: "admin_moderator", color: "#8B0000", label: "Admin" },
    // { tag: "system_legend", color: "#FF0000", label: "Legend" }, // 紅色 by Ex Excelsior (Ex) https://goo.gl/3qPscU
    { tag: "system_troll", color: "#FF00FF", label: "Troll" },
    { tag: "system_probable_troll", color: "#FF00FF", label: "Troll?" },
    { tag: "show_social_rank", color: "#2BCF5C", label: "User" },
    { tag: "system_trust_legend", color: "#8143E6", label: "Trusted" },
    { tag: "system_trust_veteran", color: "#8143E6", label: "Trusted" },
    { tag: "system_trust_trusted", color: "#FF7B42", label: "Known" },
    { tag: "system_trust_known", color: "#2BCF5C", label: "User" },
    { tag: "system_trust_intermediate", color: "#000080", label: "New" },
    { tag: "system_trust_basic", color: "#1778FF", label: "New" }, // 藍色
];
var defaultTrustTag = { color: "#808080", label: "Visitor" };
function getTrust(tags) {
    var tagsMap = (0, util_1.toExistHash)(tags);
    for (var _i = 0, trustTags_1 = trustTags; _i < trustTags_1.length; _i++) {
        var trustTag = trustTags_1[_i];
        if (tagsMap[trustTag.tag])
            return trustTag;
    }
    return defaultTrustTag;
}
var style = {
    userSelect: "none",
    color: "#fff",
};
exports.TrustMark = React.memo(function PlatformMark(_a) {
    var tags = _a.tags;
    var trust = getTrust(tags || []);
    return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Label, __assign({ circular: true, basic: true, size: "mini", style: __assign(__assign({}, style), { color: trust.color, borderColor: trust.color }) }, { children: trust.label }), void 0)
    // <Label circular size="mini" style={{...style, background: trust.color}}>{trust.label}</Label>
    );
});
