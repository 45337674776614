"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetContentsItems = void 0;
var react_1 = require("react");
var sentry_1 = require("../sentry");
var contents_1 = require("../api/contents");
function useGetContentsItems(state) {
    (0, react_1.useEffect)(function () {
        if (state.contentsItemsFetchRequest && !state.contentsItemsLoading) {
            state.setContentsItemsFetchRequest(false);
            state.setContentsItemsLoading(true);
            (0, contents_1.fetchContentsItems)().then(function (contentsItems) {
                state.setContentsItems(contentsItems);
                state.setContentsItemsLoading(false);
            }, function (error) {
                (0, sentry_1.captureException)(error);
                state.setContentsItemsLoading(false);
            });
        }
    }, [state.contentsItemsFetchRequest]);
}
exports.useGetContentsItems = useGetContentsItems;
