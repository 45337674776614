"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var semantic_ui_react_1 = require("semantic-ui-react");
var react_router_1 = require("react-router");
var AboutSection_1 = require("./AboutSection");
var Sample_1 = require("./Sample");
var MainLayout_1 = require("./MainLayout");
var FirstLogin_1 = require("./FirstLogin");
var util_1 = require("../util");
var Contact_1 = require("./Contact");
var RakusenCardman_1 = require("./RakusenCardman");
exports.TopPage = (0, react_router_1.withRouter)(function (_a) {
    var state = _a.state, location = _a.location;
    if (state.logined && !(location.state && location.state.gotoTop)) {
        return ((0, jsx_runtime_1.jsx)(react_router_1.Redirect, { to: {
                pathname: "/users",
            } }, void 0));
    }
    return ((0, jsx_runtime_1.jsx)(FirstLogin_1.FirstLogin, __assign({}, (0, util_1.pick)(state, FirstLogin_1.FirstLoginPropKeys), { children: (0, jsx_runtime_1.jsx)(MainLayout_1.MainLayout, __assign({ setLang: state.setLang }, { children: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(AboutSection_1.AboutSection, {}, void 0) }, void 0), RakusenCardman_1.showRakusenCardman && ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(RakusenCardman_1.RakusenCardman, {}, void 0) }, void 0)), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(Sample_1.Sample, { groups: state.groups }, void 0) }, void 0), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(Contact_1.Contact, {}, void 0) }, void 0)] }, void 0) }), void 0) }), void 0));
});
