"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupSummaries = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var semantic_ui_react_1 = require("semantic-ui-react");
var util_1 = require("../util");
var PagenationBySize_1 = require("./PagenationBySize");
var PagenationMenu_1 = require("./PagenationMenu");
var GroupSummary_1 = require("./GroupSummary");
var GroupSummaries = function (state) {
    var joinedIds = {};
    var joinedGroups = [];
    var notJoinedGroups = [];
    if (state.selfUser) {
        for (var _i = 0, _a = state.groups; _i < _a.length; _i++) {
            var group = _a[_i];
            if ((0, util_1.isUserJoinnedInGroup)(group, state.selfUser)) {
                joinedGroups.push(group);
                joinedIds[group.id] = true;
            }
            else {
                notJoinedGroups.push(group);
            }
        }
    }
    else {
        notJoinedGroups = state.groups;
    }
    var groups = (0, PagenationBySize_1.pagenate)(joinedGroups.concat(notJoinedGroups), state.page, state.pageSize);
    if (!groups.length)
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}, void 0);
    var menuState = (0, util_1.pick)(state, PagenationMenu_1.PagePropKeys);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(PagenationMenu_1.PagenationMenu, __assign({}, menuState, { length: state.groups.length }), void 0), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid, __assign({ doubling: true, stackable: true, columns: 4 }, { children: groups.map(function (group) { return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(GroupSummary_1.GroupSummary, { group: group, joined: joinedIds[group.id] }, void 0) }, group.id)); }) }), void 0), (0, jsx_runtime_1.jsx)(PagenationMenu_1.PagenationMenu, __assign({}, menuState, { length: state.groups.length }), void 0)] }, void 0));
};
exports.GroupSummaries = GroupSummaries;
