"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubPlaceButtons = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var semantic_ui_react_1 = require("semantic-ui-react");
var react_router_dom_1 = require("react-router-dom");
var SubPlace_1 = require("../models/SubPlace");
function subPlaceButtonStyle(currentSubPlaceId, subPlaceId, subPlace) {
    var current = currentSubPlaceId === subPlaceId;
    return {
        color: current ? subPlace.color : "white",
        background: current ? "transparent" : subPlace.color,
        border: current ? "1px solid " + subPlace.color : undefined,
    };
}
exports.SubPlaceButtons = (0, react_router_dom_1.withRouter)(function SubPlaceButtons(_a) {
    var history = _a.history, location = _a.location;
    var currentSubPlaceId = SubPlace_1.subPlaceOrders.filter(function (subPlaceId) {
        return location.pathname.startsWith(SubPlace_1.subPlaces[subPlaceId].path);
    })[0];
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Responsive, __assign({ minWidth: "600" }, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button.Group, { children: SubPlace_1.subPlaceOrders
                        .map(function (subPlaceId) { return [subPlaceId, SubPlace_1.subPlaces[subPlaceId]]; })
                        .map(function (_a) {
                        var subPlaceId = _a[0], subPlace = _a[1];
                        return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, __assign({ as: "span", className: "site-title-font", style: subPlaceButtonStyle(currentSubPlaceId, subPlaceId, subPlace), onClick: function () { return history.push(subPlace.path); } }, { children: subPlace.title }), subPlace.path));
                    }) }, void 0) }), void 0), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Responsive, __assign({ maxWidth: "599" }, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button.Group, { children: SubPlace_1.subPlaceOrders
                        .map(function (subPlaceId) { return [subPlaceId, SubPlace_1.subPlaces[subPlaceId]]; })
                        .map(function (_a) {
                        var subPlaceId = _a[0], subPlace = _a[1];
                        return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, __assign({ as: "span", className: "site-title-font", style: subPlaceButtonStyle(currentSubPlaceId, subPlaceId, subPlace), onClick: function () { return history.push(subPlace.path); } }, { children: subPlace.title[0] }), subPlace.path));
                    }) }, void 0) }), void 0)] }, void 0));
});
