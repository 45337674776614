"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackupDB = void 0;
var idb_1 = require("idb");
function profileProp(profile) {
    return {
        id: profile.id,
        privateMemo: profile.privateMemo,
        privateMemoIsSynced: profile.privateMemoIsSynced,
        privateMemoCreatedAt: profile.privateMemoCreatedAt,
        privateMemoUpdatedAt: profile.privateMemoUpdatedAt,
    };
}
var BackupDB = /** @class */ (function () {
    function BackupDB() {
    }
    BackupDB.prototype.openDb = function () {
        var _this = this;
        if (this.opening)
            return this.opening;
        this.opening = (0, idb_1.openDB)("vrcprofile_client", 1, {
            upgrade: function (db) {
                db.createObjectStore("userProfiles", { keyPath: "id" });
                db.createObjectStore("userDefinedGroups", { keyPath: "id" });
            },
        }).then(function (db) {
            _this.db = db;
            return _this;
        });
        return this.opening;
    };
    BackupDB.prototype.readonly = function (storeName) {
        return this.db.transaction(storeName, "readonly");
    };
    BackupDB.prototype.readwrite = function (storeName) {
        return this.db.transaction(storeName, "readwrite");
    };
    BackupDB.prototype.putUserProfiles = function (profiles) {
        var tx = this.readwrite("userProfiles");
        return Promise.all(__spreadArray(__spreadArray([], profiles.map(function (profile) { return tx.objectStore("userProfiles").put(profile); }), true), [tx.done], false));
    };
    BackupDB.prototype.putUserDefinedGroups = function (groups) {
        var tx = this.readwrite("userDefinedGroups");
        return Promise.all(__spreadArray(__spreadArray([], groups.map(function (group) { return tx.objectStore("userDefinedGroups").put(group); }), true), [tx.done], false));
    };
    return BackupDB;
}());
exports.BackupDB = BackupDB;
