"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchUsernameButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var semantic_ui_react_1 = require("semantic-ui-react");
var withState_1 = require("../hocs/withState");
exports.SearchUsernameButton = (0, withState_1.withState)()([
    "setSearchParams",
]).memoFC(function (state) { return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, __assign({ icon: true, size: "mini", onClick: function () {
        state.setSearchParams({ name: "username", value: state.username });
    } }, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "search" }, void 0) }), void 0)); });
