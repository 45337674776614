"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorldStatusIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var React = __importStar(require("react"));
var semantic_ui_react_1 = require("semantic-ui-react");
var ILocationInfo_1 = require("../models/ILocationInfo");
var Permission_1 = require("../models/Permission");
var util_1 = require("../util");
exports.WorldStatusIcon = React.memo(function (_a) {
    var worldId = _a.worldId, instanceId = _a.instanceId, permission = _a.permission, owner = _a.owner, region = _a.region, world = _a.world, loading = _a.loading, traveling = _a.traveling, _b = _a.type, type = _b === void 0 ? "popup" : _b;
    var href;
    if (permission === "offline" || permission === "none") {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}, void 0);
    }
    if (type === "link") {
        var url = (0, util_1.locationInfoToTsubokuraUrl)({ worldId: worldId, instanceId: instanceId, permission: permission, owner: owner });
        if (url)
            href = url.href;
    }
    var text = world ? (world.name) : permission !== "private" && permission !== "error" ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [Permission_1.permissionText[permission], (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Loader, { active: loading, inline: true, size: "mini" }, void 0)] }, void 0)) : (Permission_1.permissionText[permission]);
    return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Label, __assign({ as: href ? "a" : undefined, href: href, target: "_blank", rel: "noopener noreferrer", style: ILocationInfo_1.isJoinablePermission[permission] ? { cursor: "pointer" } : undefined, color: Permission_1.permissionColor[permission] }, { children: [traveling && (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "arrow alternate circle right outline" }, void 0), text, !!world && ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Label.Detail, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Flag, { name: region }, void 0) }, void 0)), ILocationInfo_1.isGroupPermission[permission] && (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "object group outline" }, void 0)] }), void 0));
});
