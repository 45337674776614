"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFirstGetGroupUsers = void 0;
var react_1 = require("react");
var useGetGroupUsers_1 = require("./useGetGroupUsers");
function useFirstGetGroupUsers(state, groupId) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    var group = groupId ? state.groups.filter(function (group) { return group.id === groupId; })[0] : undefined;
    (0, react_1.useEffect)(function () {
        if (!group)
            return;
        // first fetch
        state.setGroupUsersFetchRequest(group.tagName);
        // unmount cancel
        return function () {
            (0, useGetGroupUsers_1.abortGetGroupUsers)();
            state.clearGroupUsers();
        };
    }, [group && group.tagName]);
}
exports.useFirstGetGroupUsers = useFirstGetGroupUsers;
