"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toBodyData = exports._delete = exports.put = exports.patch = exports.post = exports.get = exports.fetchWithCredentials = void 0;
function fetchWithCredentials(input, init) {
    return fetch(input, __assign(__assign({ credentials: "same-origin" }, (init || {})), { headers: __assign({ "x-vrcp-auth": "true" }, (init ? init.headers || {} : {})) }));
}
exports.fetchWithCredentials = fetchWithCredentials;
exports.get = fetchWithCredentials;
function genHasBodyFetchMethod(method) {
    var func = function bodyFetchMethod(input, init) {
        if (!init)
            init = {};
        var isFormData = init.body instanceof FormData;
        if (init.body && !isFormData)
            init.body = init.body.toString();
        var defaultHeaders = isFormData
            ? {}
            : {
                "Content-Type": "application/x-www-form-urlencoded",
            };
        return fetchWithCredentials(input, __assign(__assign({ method: method }, init), { headers: __assign(__assign({}, defaultHeaders), (init.headers || {})) }));
    };
    return func;
}
exports.post = genHasBodyFetchMethod("POST");
exports.patch = genHasBodyFetchMethod("PATCH");
exports.put = genHasBodyFetchMethod("PUT");
exports._delete = genHasBodyFetchMethod("DELETE");
function toBodyData(data) {
    var newData = __assign({}, data);
    for (var _i = 0, _a = Object.keys(newData); _i < _a.length; _i++) {
        var key = _a[_i];
        if (newData[key] == null || newData[key] === "")
            delete newData[key];
    }
    return new URLSearchParams(newData);
}
exports.toBodyData = toBodyData;
