"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReadonlyUser = exports.ReadonlyUserRaw = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var Profile_1 = require("./Profile");
var CompactProfile_1 = require("./CompactProfile");
var UserBase_1 = require("./UserBase");
var CompactUserBase_1 = require("./CompactUserBase");
var UserProfileTag_1 = require("./UserProfileTag");
var ShowProfilePrivateInfo_1 = require("./ShowProfilePrivateInfo");
var UserGroups_1 = require("./UserGroups");
var withState_1 = require("../hocs/withState");
var connect = (0, withState_1.withState)()([
    "lang",
    "groups",
    "searchParams",
    "setSearchTags",
    "compactView",
    "getUserFirstUnmuteAt",
]);
function ReadonlyUserRaw(state) {
    var UserBaseComponent = state.compactView ? CompactUserBase_1.CompactUserBase : UserBase_1.UserBase;
    var ProfileComponent = state.compactView ? CompactProfile_1.CompactProfile : Profile_1.Profile;
    return ((0, jsx_runtime_1.jsx)(UserBaseComponent, { user: state.user, world: state.world, worldLoading: state.worldLoading, color: state.color, main: (0, react_1.useMemo)(function () { return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(ProfileComponent, { profile: state.user.profile, bio: state.user.bio, friendAt: state.getUserFirstUnmuteAt(state.user.id) }, void 0), (0, jsx_runtime_1.jsx)(UserProfileTag_1.UserProfileTag, { userProfile: state.user.profile, searchTags: state.searchParams.tags, setSearchTags: state.setSearchTags }, void 0), (0, jsx_runtime_1.jsx)(UserGroups_1.UserGroups, { user: state.user }, void 0), (0, jsx_runtime_1.jsx)(ShowProfilePrivateInfo_1.ShowProfilePrivateInfo, { user: state.user }, void 0)] }, void 0)); }, [state.user, state.getUserFirstUnmuteAt, state.searchParams.tags, state.setSearchTags]) }, void 0));
}
exports.ReadonlyUserRaw = ReadonlyUserRaw;
exports.ReadonlyUser = connect.memoFC(ReadonlyUserRaw);
