"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RakusenCardman = exports.showRakusenCardman = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var containerStyle = { textAlign: "center" };
var textStyle = { color: "#999", fontSize: "smaller" };
var imageStyle = { maxWidth: "100%", maxHeight: "114px", border: "1px solid #999" };
exports.showRakusenCardman = new Date() >= new Date(2019, 11 - 1, 23, 0, 0, 0) && new Date() < new Date(2019, 12 - 1, 9, 0, 0, 0);
function RakusenCardman() {
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: containerStyle }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: textStyle }, { children: "\u5E83\u544A" }), void 0), (0, jsx_runtime_1.jsx)("a", __assign({ href: "https://twitter.com/IspVitamin/status/1177478611638423554", target: "_blank", rel: "noopener noreferrer" }, { children: (0, jsx_runtime_1.jsx)("img", { src: "./virtual_steel_museum_2.png", style: imageStyle }, void 0) }), void 0)] }), void 0));
}
exports.RakusenCardman = RakusenCardman;
