"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllStateContext = exports.IncrementErrorCountContext = void 0;
var react_1 = require("react");
function createContextDefault() {
    return (0, react_1.createContext)(undefined);
}
// root
exports.IncrementErrorCountContext = createContextDefault();
// common
exports.AllStateContext = createContextDefault();
