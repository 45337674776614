"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileTag = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var React = __importStar(require("react"));
var util_1 = require("../util");
var tagStyle = { display: "inline-block", padding: ".1em .2em", margin: "0 .1em", cursor: "pointer" };
var selectedTagStyle = __assign(__assign({}, tagStyle), { color: "#fff", background: "#1678c2" });
exports.ProfileTag = React.memo(function (_a) {
    var tags = _a.tags, _b = _a.searchTags, searchTags = _b === void 0 ? [] : _b, setSearchTags = _a.setSearchTags;
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: tags.map(function (tag) { return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid
        (0, jsx_runtime_1.jsxs)("a", __assign({ style: searchTags.indexOf(tag.name) !== -1 ? selectedTagStyle : tagStyle, onClick: setSearchTags ? function () { return setSearchTags((0, util_1.toggleTag)(searchTags, tag.name)); } : undefined }, { children: ["#", tag.name, tag.count ? "(" + tag.count + ")" : ""] }), tag.name)); }) }, void 0));
});
