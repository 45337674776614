"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
exports.default = {
    reportErrorTitle: "バグっぽいですか？",
    reportErrorMessage1: "VRChat Profileのバグかな？と思った場合は、",
    reportErrorMessage2: "にお気軽にリプライ下さい。",
    reportErrorIgnore: "問題ありません",
    reportErrorAccept: "バグ報告する",
    vmMessageTitle: "バーチャルマーケット3開催中!",
    vmMessageContent: "VRChat Profileはバーチャルマーケット3を応援しています。",
    omMessageTitle: "落選マーケット開催中!",
    omMessageContent: "VRChat Profileは落選マーケットを応援しています。",
    vfMessageTitle: "バーチャルフロンティア開催中!",
    vfMessageContent: "VRChat Profileはバーチャルフロンティアを応援しています。",
    vrcUnstableMessageTitle: "VRChatの負荷対策のためフレンド取得が不安定になります",
    vrcUnstableMessageContent: "VRChatが負荷対策のため意図的にフレンド取得を制限している模様です。当サイトでのフレンド取得も不安定になりますので、オンライン状態や「インスタンス」タブなどが正常に動作しない可能性があります。ご不便をおかけします。",
    vrcUnavailableMessageTitle: "VRChat APIの仕様変更のため一時的に利用不可",
    vrcUnavailableMessageContent: "VRChat APIの仕様変更により当サービスが利用できなくなっています。調査を進めていますが、解決までしばらく時間がかかる可能性があります。",
    messageTitle: "メンテナンス（ダウンタイムあり）を予定しています",
    messageContent: "本日2019/3/7 13:30から数分～数十分間VRChat Profileが利用できなくなります。\nあらかじめご了承ください。",
    isUnsupportedBrowserMessageTitle: "未対応のブラウザです",
    isOldEdgeMessageContent: "Windows 10 October 2018 Update以前のMicrosoft Edgeには非対応です（一部機能が動作しません）。他のブラウザをご利用ください。",
    siteDescriptionTitle: "VRChatソーシャルをもっと便利に！",
    siteDescription: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["VRChat Profile\u306FVRChat\u306B\u8DB3\u308A\u306A\u3044\u30BD\u30FC\u30B7\u30E3\u30EB\u6A5F\u80FD\u3092\u88DC\u5B8C\u3057\u3001VRChat\u3092\u3082\u3063\u3068\u4FBF\u5229\u306B\u697D\u3057\u3080\u305F\u3081\u306E\u30C4\u30FC\u30EB\u3067\u3059\u3002", (0, jsx_runtime_1.jsx)("br", {}, void 0), "\u6700\u521D\u306FVRChat\u306E\u30D5\u30EC\u30F3\u30C9\u304C\u8AB0\u304C\u8AB0\u3060\u304B\u5206\u304B\u3089\u306A\u304F\u306A\u308B\u306E\u3067\u30E1\u30E2\u3059\u308B\u305F\u3081\u306B\u4F5C\u3063\u305F\u3082\u306E\u3067\u3059\u304C\u3001\u3044\u308D\u3044\u308D\u62E1\u5145\u3057\u3066\u4FBF\u5229\u306B\u306A\u3063\u3066\u307E\u3059\u3002", (0, jsx_runtime_1.jsx)("br", {}, void 0), "\u6A5F\u80FD\u306E\u8981\u671B\u3084\u6539\u5584\u6848\u304C\u3042\u308C\u3070\u662F\u975E", (0, jsx_runtime_1.jsx)("a", __assign({ href: "https://twitter.com/narazaka", target: "_blank", rel: "noopener noreferrer" }, { children: "@narazaka (Twitter)" }), void 0), "\u307E\u3067\u304A\u6C17\u8EFD\u306B\u3054\u63D0\u6848\u304F\u3060\u3055\u3044\u3002"] }, void 0)),
    usersDescription: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["VRChat\u306E\u30D5\u30EC\u30F3\u30C9\u306E\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB\u304C\u898B\u3089\u308C\u305F\u308A\u3001\u3069\u3046\u3044\u3046\u4EBA\u304B\u500B\u4EBA\u7684\u306B\u30E1\u30E2\u3067\u304D\u305F\u308A\u3057\u307E\u3059\u3002", (0, jsx_runtime_1.jsx)("br", {}, void 0), "\u81EA\u5206\u306E\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB\u3082\u767B\u9332\u3057\u3066\u691C\u7D22\u6027\u3092\u3088\u304F\u3057\u3088\u3046\uFF01\uFF08\u3066\u304D\u3068\u3046", (0, jsx_runtime_1.jsx)("br", {}, void 0), "\u307E\u305F\u30D5\u30EC\u30F3\u30C9\u304C\u4ECA\u3044\u308B\u30A4\u30F3\u30B9\u30BF\u30F3\u30B9\u4E00\u89A7\u306A\u3069\u3082\u898B\u3089\u308C\u307E\u3059\u3002\u4FBF\u5229\uFF01", (0, jsx_runtime_1.jsx)("br", {}, void 0)] }, void 0)),
    groupsDescription: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["VRChat\u306B\u3042\u308B\u30B0\u30EB\u30FC\u30D7\u7684\u306A\u3082\u306E\u3092\u767B\u9332\u3067\u304D\u307E\u3059\u3002", (0, jsx_runtime_1.jsx)("br", {}, void 0), "\u3044\u308F\u3086\u308B\u300C\u25CB\u25CB\u30D5\u30A9\u30FC\u30B9\u300D\u4EE5\u5916\u306B\u3082\u3001discord\u3067\u3064\u306A\u304C\u3063\u305F\u500B\u4EBA\u7684\u306A\u754C\u9688\u3068\u304B\u3082\u767B\u9332\u3057\u3066\u304A\u304F\u3068\u6357\u308B\u304B\u3082\uFF1F", (0, jsx_runtime_1.jsx)("br", {}, void 0)] }, void 0)),
    contentsDescription: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["VRChat\u95A2\u9023\u306E\u5275\u4F5C\u3092\u30B7\u30A7\u30A2\u3057\u307E\u3057\u3087\u3046\uFF01", (0, jsx_runtime_1.jsx)("br", {}, void 0), "VRChat\u6F2B\u753B\u3084\u3001VRChat\u30D5\u30EC\u30F3\u30C9\u3092\u63CF\u3044\u305F\u7D75\u3068\u3001\u63CF\u304B\u308C\u3066\u3044\u308B\u30D5\u30EC\u30F3\u30C9\u304C\u7D10\u4ED8\u3051\u3089\u308C\u307E\u3059\u3002", (0, jsx_runtime_1.jsx)("br", {}, void 0), "\u73FE\u72B6Twitter\u306B\u5BFE\u5FDC\u3002"] }, void 0)),
    descriptionCaution: "※このツールはVRChat APIを利用していますが、VRChat公式とは無関係です。",
    aboutTitle: "このサイトについて",
    forDevelopers: "開発者の方へ",
    privacyPolicy: "プライバシーポリシー",
    i18nContribute: "翻訳協力",
    profileCount: "プロフィール登録数",
    memoUserCount: "個人メモ登録ユーザー数",
    sampleTitleAprilFool: "圧が強い (プロフィール表示サンプル)",
    sampleTitle: "作者（プロフィール表示サンプル）",
    sampleProfilePrefix: "↓★★★プロフィールを書こう！★★★\n\n",
    sampleTagPrefix: "★★★タグも付けよう★★★",
    samplePrivateMemo: "↓★★★個人的なメモも書けます★★★\n\nリボンポニテ八重歯メガネセーラー服",
    samplePrivateMemoAprilFool: "↓★★★個人的なメモも書けます★★★\n\n妖怪",
    sampleLoadError: "サンプルプロフィールを読み込めませんでした。 ネットワークを確認し、このページをリロードしてください。",
    username: "ユーザー名",
    usernameNotice: "ログイン時のユーザー名",
    password: "パスワード",
    twoFactorAuthCode: "二段階認証コード",
    twoFactorAuthDescription: "認証アプリに表示されるコードを入力してください",
    loginNotice: "パスワードはVRChatのユーザー認証のみに使っており、サーバーには送られますが保存はされません。（httpsで暗号化して送信されます）\n" +
        "（本当はTwitter認証みたいにしたいけれど、現状技術的にサーバーにパスワードまで送る以外の方法で本人認証する方法がないので……）\n" +
        "（VRChat APIにOAuthとかが実装されると幸せになるはず）\n" +
        "「ログイン状態を維持」はパスワードの推測が不可能な暗号化されたセッションクッキーを保持します。",
    loginNoticeNoExtension: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("a", __assign({ href: "https://www.google.com/chrome/", target: "_blank", rel: "noopener noreferrer" }, { children: "Google Chrome (PC)" }), void 0), ",", " ", (0, jsx_runtime_1.jsx)("a", __assign({ href: "https://www.mozilla.org/firefox/", target: "_blank", rel: "noopener noreferrer" }, { children: "Firefox (PC)" }), void 0), false && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [",", " ", (0, jsx_runtime_1.jsx)("a", __assign({ href: "https://play.google.com/store/apps/details?id=org.mozilla.firefox", target: "_blank", rel: "noopener noreferrer" }, { children: "Firefox Mobile (Android)" }), void 0)] }, void 0)), " ", "\u7B49\u3067\u306F\u62E1\u5F35\u6A5F\u80FD\u306B\u3088\u3063\u3066\u672C\u30B5\u30A4\u30C8\u306B\u76F4\u63A5\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u5165\u529B\u3059\u308B\u3053\u3068\u306A\u304F\u30ED\u30B0\u30A4\u30F3\u3059\u308B\u3053\u3068\u304C\u53EF\u80FD\u3067\u3059\u3002", (0, jsx_runtime_1.jsx)("br", {}, void 0)] }, void 0)),
    loginBrowserRecommend: "これらの対応ブラウザを使うことをおすすめします。",
    loginNoticeWithExtension: "拡張機能によってvrchat.comのセッションクッキーを使ってログインすることができます。\n" +
        "（セッションクッキー情報からパスワードは推測・変更不可能です）\n" +
        "（本当はTwitter認証みたいにしたいけれど、セッション情報を送る以外の方法で本人認証する方法がないので……）\n" +
        "（VRChat APIにOAuthとかが実装されると幸せになるはず）\n" +
        "「ログイン状態を維持」はパスワードの推測が不可能な暗号化されたセッションクッキーを保持します。",
    loginCautionTitle: "アカウント情報入力の危険性について",
    loginCaution: "VRChat ProfileはVRChatとは無関係の個人が運営するサービスです。\n" +
        "VRChatのアカウント情報を入力することは、そのサービスの運営者にアカウントの全ての操作を行える権限を与え、またそのサービスの脆弱性がアカウントに影響することを意味します。" +
        "(VRChat Profileはプライバシーポリシーのとおり不正操作を行いませんが、それが本当に守られているかどうかは現状技術的に証明不可能です。)\n" +
        "この危険性を考慮の上、運営者を信頼できる場合にのみ自己責任でご利用をお願い致します。（これは現行のVRChatの認証方式そのものによる問題なので、他のVRChat関連サービスにおいても同様にお気を付け下さい）",
    installExtension: "拡張機能をインストール",
    loginWithExtension: "vrchat.comでログイン",
    loginFallbackToNoExtension: "従来方式でログイン",
    login: "ログイン",
    logout: "ログアウト",
    loginfailed: "ログイン失敗",
    keepLoggedIn: "ログイン状態を維持",
    missingCredentialsLogout: "ログイン状態が切れました。\n再度ログインする必要があります。\n今すぐログイン画面へ遷移しますか？",
    missingCredentialsLogoutWithExtension: "ログインできませんでした。\n一度https://vrchat.com/homeでログアウトしてください。",
    getFriends: "フレンド情報取得",
    noUsers: "ユーザーが見つかりませんでした",
    friends: "フレンド",
    nonFriendUser: "フレンド以外のユーザー",
    my: "My",
    world: "ワールド",
    instances: "インスタンス",
    getDetails: "詳細を取得",
    platform: "プラットフォーム",
    search: "検索",
    searchByAccount: "アカウントで検索",
    saveSearchParams: "検索条件を保存",
    searchParamsName: "条件名",
    profile: "プロフィール",
    twitter: "Twitter",
    steam: "Steam",
    discord: "Discord",
    discordInviteUrl: "Discord招待URL",
    discordInviteUrlShouldNotExpire: "招待URLに有効期限を設定しないことをおすすめします",
    booth: "Booth",
    vroidhub: "VRoid Hub",
    vroidhubDescription: "「あなたのキャラクター」ページのURLの/users/(ここの数字部分)",
    github: "GitHub",
    gitlab: "Gitlab",
    youtube: "YouTube",
    niconico: "niconico",
    joinedAt: "はじめた日",
    ambiguousToBlank: "曖昧な場所は空欄にして下さい。",
    birthday: "誕生日",
    friendAt: "フレンドになった日",
    friendAtWithCaution: "フレンドになった日（一部ユーザーのみ）",
    site: "Webサイト",
    tags: "タグ",
    tagsDescription: "みんなが見られるタグ機能",
    lockTags: "タグをロックする",
    privateMemo: "メモ(プライベート)",
    privateMemoDescription: "プライベートなメモ機能",
    permission: "インスタンス状態",
    advancedSearch: "詳細オプション",
    haveProfile: "プロフィールあり",
    havePrivateMemo: "メモ(プライベート)の有無",
    showOnlyUserExistsTab: "0人のタブを隠す",
    syncPrivateMemo: "メモ(プライベート)をVRChat.comに同期",
    syncingPrivateMemo: "メモ(プライベート)をVRChat.comに同期中...",
    syncPrivateMemoDescription: "当サービスで編集したメモ(プライベート)をVRChat.comのnote欄に同期します。",
    nosyncPrivateMemo: "未同期のメモ",
    syncPrivateMemoByThis: "このメモを同期",
    syncPrivateMemoTooLong: "長すぎて自動同期出来ません",
    syncPrivateMemoMustManual: "VRChat.comに同期されていないメモがありますが自動同期出来ません",
    compactView: "コンパクト表示",
    sortByInstance: "インスタンスごとにソート",
    any: "全て",
    exists: "有り",
    notExists: "無し",
    show: "表示",
    hide: "隠す",
    page: "ページ",
    perPage: "件ごと",
    cancel: "キャンセル",
    reset: "リセット",
    edit: "編集",
    editName: "名前を編集",
    editUsers: "ユーザーを編集",
    create: "作成",
    update: "更新",
    delete: "削除",
    add: "追加",
    saved: "保存済み",
    save: "保存",
    completeEditUsers: "ユーザー編集を完了",
    back: "戻る",
    cancelConfirm: "変更内容が失われますがキャンセルしますか？",
    deleteConfirm: "本当に削除しますか？",
    required: "必須",
    overMaxLength: "長すぎます",
    loading: "Loading",
    saving: "Saving",
    image: "画像",
    imageMaxSize: "最大500KB",
    imageType: "PNG / GIF / JPEG",
    dropOrChooseFile: "ファイルをドロップ or クリックしてファイルを選択",
    showProfileNotFound: "ユーザーがVRchat Profileで取得されたことがありません。",
    showProfileDescription: "VRChat Profileでプロフィールを公開しよう！",
    share: "シェア",
    twitterText: "VRChatで会いましょう！",
    max: "最大",
    min: "最小",
    year: "年",
    month: "月",
    date: "日",
    minuteAgo: "分前",
    minutesAgo: "分前",
    overOneHourAgo: "1時間以上前",
    lastTime: "前回",
    createdAt: "作成日",
    updatedAt: "更新日",
    createUserDefinedGroupHelp: "新しい「お気に入りグループ」を作成します（VRChat Profile上のみ）",
    addUserDefinedGroupUserHelp: "編集中のお気に入りグループにユーザーを追加します",
    removeUserDefinedGroupUserHelp: "編集中のお気に入りグループからユーザーを削除します",
    addUserDefinedGroupPrimaryUserHelp: "編集中のお気に入りグループで注目ユーザーにします",
    removeUserDefinedGroupPrimaryUserHelp: "編集中のお気に入りグループで注目ユーザーから外します",
    group: "グループ",
    createGroup: "グループを新規作成",
    getGroups: "グループ情報を取得",
    noGroups: "グループが見つかりませんでした",
    groupName: "グループ名",
    groupSummary: "キャッチフレーズ（70字）",
    groupProfile: "グループプロフィール",
    groupCreatedAt: "設立日",
    groupTag: "グループメンバータグ",
    joined: "参加済み",
    contents: "作品",
    createContents: "作品を登録する",
    noContents: "作品が見つかりませんでした",
    contentsSeriesName: "名前（シリーズ名など）",
    worldInstanceDetailDisabledCaution: "VRChat APIの仕様変更のため、詳細取得は現在利用できません。",
    worldName: "ワールド名",
    description: "説明",
    authorName: "作者名",
    capacity: "人数制限",
    public: "Public",
    communityLabs: "Labs",
    private: "Private",
    publicStatus: "公開状態",
    publicationDate: "Public公開日",
    labsPublicationDate: "Labs公開日",
    favorites: "お気に入り数",
    heat: "HEAT",
    visits: "訪問者数",
};
