"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.remoteConfig = void 0;
var firebase = __importStar(require("firebase/app"));
require("firebase/analytics");
require("firebase/performance");
require("firebase/remote-config");
firebase.initializeApp({
    apiKey: "AIzaSyBU9Qoya6WRzMYbtj-CYkA_eRevFrbqlfs",
    authDomain: "vrchat-profile.firebaseapp.com",
    databaseURL: "https://vrchat-profile.firebaseio.com",
    projectId: "vrchat-profile",
    storageBucket: "vrchat-profile.appspot.com",
    messagingSenderId: "269703467196",
    appId: "1:269703467196:web:69cf9f24dc3347cc0280b9",
    measurementId: "G-1HH54K5ZRC",
});
firebase.analytics();
firebase.performance();
exports.remoteConfig = firebase.remoteConfig();
exports.remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
