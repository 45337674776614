"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorldMinePage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var react_router_1 = require("react-router");
var util_1 = require("../util");
var World_1 = require("./World");
var PagenationMenu_1 = require("./PagenationMenu");
var PagenationBySize_1 = require("./PagenationBySize");
var SearchWorldsForm_1 = require("./SearchWorldsForm");
exports.WorldMinePage = (0, react_router_1.withRouter)(function WorldPublics(props) {
    var page = props.page, setPage = props.setPage, worldsPageSize = props.worldsPageSize, setWorldsPageSize = props.setWorldsPageSize, worlds = props.worlds, worldSearchParams = props.worldSearchParams, selfUser = props.selfUser;
    var targetWorldIds = (0, react_1.useMemo)(function () { return Object.keys(worlds).filter(function (id) { return worlds[id].authorId === selfUser.id; }); }, [
        worlds,
    ]);
    var pageProps = (0, util_1.routePageProps)(props, {
        page: page("worldsPublic"),
        setPage: function (_page) { return setPage("worldsPublic", _page); },
        pageSize: worldsPageSize,
        setPageSize: setWorldsPageSize,
    });
    var searchWorlds = (0, util_1.genSearchWorlds)({ worldSearchParams: worldSearchParams, worlds: worlds });
    var sWorldIds = searchWorlds(targetWorldIds);
    var useWorldIds = (0, PagenationBySize_1.pagenate)(sWorldIds, pageProps.page, pageProps.pageSize);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(SearchWorldsForm_1.SearchWorldsForm, {}, void 0), (0, jsx_runtime_1.jsx)(PagenationMenu_1.PagenationMenu, __assign({}, pageProps, { length: sWorldIds.length }), void 0), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid, __assign({ doubling: true, stackable: true, columns: 4 }, { children: useWorldIds.length ? (useWorldIds
                    .map(function (id) { return worlds[id]; })
                    .map(function (world) { return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(World_1.World, { world: world }, void 0) }, world.id)); })) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}, void 0)) }), void 0)] }, void 0));
});
