"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VrcWorldPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var semantic_ui_react_1 = require("semantic-ui-react");
var react_router_1 = require("react-router");
var react_1 = require("react");
var World_1 = require("./World");
var MainLayout_1 = require("./MainLayout");
var api_1 = require("../api");
var util_1 = require("../util");
var Permission_1 = require("../models/Permission");
var ILocationInfo_1 = require("../models/ILocationInfo");
var i18n_1 = require("../i18n");
var defaultRegion = {
    ja: "jp",
    ko: "jp",
    en: "us",
};
var options = ILocationInfo_1.regions.map(function (region) { return ({ key: region, value: region, flag: region, text: region }); });
exports.VrcWorldPage = (0, react_router_1.withRouter)(function VrcWorldPage(props) {
    var worlds = props.worlds, selfUser = props.selfUser, setLang = props.setLang, match = props.match, history = props.history;
    var id = match.params.id;
    var world = worlds[id];
    var instanceId = (0, react_1.useMemo)(function () { return Math.floor(Math.random() * 99999).toString(); }, []);
    var _a = (0, react_1.useState)(defaultRegion[(0, i18n_1.lang)()]), region = _a[0], setRegion = _a[1];
    return ((0, jsx_runtime_1.jsx)(MainLayout_1.MainLayout, __assign({ setLang: setLang }, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Container, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, __assign({ onClick: function () { return history.goBack(); } }, { children: "\u623B\u308B" }), void 0), (0, jsx_runtime_1.jsx)("br", {}, void 0), (0, jsx_runtime_1.jsx)(World_1.World, { world: world }, void 0), (0, jsx_runtime_1.jsx)("br", {}, void 0), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Dropdown, { selection: true, options: options, value: region, onChange: function (e, _a) {
                        var value = _a.value;
                        return setRegion(value);
                    } }, void 0), (0, jsx_runtime_1.jsx)("br", {}, void 0), (0, jsx_runtime_1.jsx)(LaunchInstanceButtons, { worldId: world.id, instanceId: instanceId, owner: selfUser.id, permission: "public", region: region }, void 0), (0, jsx_runtime_1.jsx)("br", {}, void 0), (0, jsx_runtime_1.jsx)(LaunchInstanceButtons, { worldId: world.id, instanceId: instanceId, owner: selfUser.id, permission: "friends-plus", region: region }, void 0), (0, jsx_runtime_1.jsx)("br", {}, void 0), (0, jsx_runtime_1.jsx)(LaunchInstanceButtons, { worldId: world.id, instanceId: instanceId, owner: selfUser.id, permission: "friends", region: region }, void 0), (0, jsx_runtime_1.jsx)("br", {}, void 0), (0, jsx_runtime_1.jsx)(LaunchInstanceButtons, { worldId: world.id, instanceId: instanceId, owner: selfUser.id, permission: "invite-plus", region: region }, void 0), (0, jsx_runtime_1.jsx)("br", {}, void 0), (0, jsx_runtime_1.jsx)(LaunchInstanceButtons, { worldId: world.id, instanceId: instanceId, owner: selfUser.id, permission: "invite", region: region }, void 0), (0, jsx_runtime_1.jsx)("br", {}, void 0)] }, void 0) }), void 0));
});
var LaunchInstanceButtons = function (info) { return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(LaunchButton, __assign({}, info), void 0), (0, jsx_runtime_1.jsx)(InviteMe, __assign({}, info), void 0), (0, jsx_runtime_1.jsx)(VrcLink, __assign({}, info), void 0)] }, void 0)); };
var VrcLink = function (info) { return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Label, __assign({ size: "large", as: "a", target: "_blank", rel: "noopener noreferrer", href: (0, util_1.locationInfoToVRChatComUrl)((0, util_1.withInstanceFullId)(info)), color: Permission_1.permissionColor[info.permission] }, { children: ["vrchat.com", (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Label.Detail, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "external" }, void 0) }, void 0)] }), "instanceId")); };
var LaunchButton = function (info) { return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Label, __assign({ size: "large", as: "a", href: (0, util_1.locationInfoToLaunchUrl)((0, util_1.withInstanceFullId)(info)), color: Permission_1.permissionColor[info.permission] }, { children: [info.instanceId, (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Label.Detail, { children: [Permission_1.permissionText[info.permission], (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "external" }, void 0)] }, void 0)] }), "instanceId")); };
var InviteMe = function (info) { return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Label, __assign({ size: "large", as: "a", circular: true, color: Permission_1.permissionColor[info.permission], onClick: function () { return (0, api_1.sendInviteMe)((0, util_1.locationInfoToString)((0, util_1.withInstanceFullId)(info))).then(function () { return alert("invite sent!"); }); } }, { children: "invite me" }), "inviteMe")); };
