"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = exports.UserRaw = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var Profile_1 = require("./Profile");
var CompactProfile_1 = require("./CompactProfile");
var UserBase_1 = require("./UserBase");
var CompactUserBase_1 = require("./CompactUserBase");
var EditProfilePrivateInfoButton_1 = require("./EditProfilePrivateInfoButton");
var ProfilePrivateInfo_1 = require("./ProfilePrivateInfo");
var EditableProfileTag_1 = require("./EditableProfileTag");
var EditProfileTagButton_1 = require("./EditProfileTagButton");
var UserGroups_1 = require("./UserGroups");
var withState_1 = require("../hocs/withState");
var UserDefinedGroupUserEditButtons_1 = require("./UserDefinedGroupUserEditButtons");
var util_1 = require("../util");
var SearchUsernameButton_1 = require("./SearchUsernameButton");
var VrcWebUserPageLinkButton_1 = require("./VrcWebUserPageLinkButton");
var connect = (0, withState_1.withState)()([
    "lang",
    "compactView",
    "groups",
    "editTags",
    "searchParams",
    "editProfilePrivateInfo",
    "tempPrivateMemo",
    "tempPrivateTags",
    "profilePrivateInfoSaving",
    "getUserFirstUnmuteAt",
    "editingUserDefinedGroup",
]);
function UserRaw(state) {
    var UserBaseComponent = state.compactView ? CompactUserBase_1.CompactUserBase : UserBase_1.UserBase;
    var ProfileComponent = state.compactView ? CompactProfile_1.CompactProfile : Profile_1.Profile;
    var main = (0, react_1.useMemo)(function () { return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(ProfileComponent, { profile: state.user.profile, bio: state.user.bio, friendAt: state.getUserFirstUnmuteAt(state.user.id) }, void 0), (0, jsx_runtime_1.jsx)(EditableProfileTag_1.EditableProfileTag, { user: state.user }, void 0), (0, jsx_runtime_1.jsx)(UserGroups_1.UserGroups, { user: state.user }, void 0), (0, jsx_runtime_1.jsx)(ProfilePrivateInfo_1.ProfilePrivateInfo, { user: state.user }, void 0)] }, void 0)); }, [state.user, state.getUserFirstUnmuteAt, state.compactView]);
    var besideName = (0, react_1.useMemo)(function () { return (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(SearchUsernameButton_1.SearchUsernameButton, { username: state.user.displayName }, void 0), (0, jsx_runtime_1.jsx)(VrcWebUserPageLinkButton_1.VrcWebUserPageLinkButton, { id: state.user.id }, void 0)] }, void 0); }, [state.user]);
    var footer = (0, react_1.useMemo)(function () { return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(EditProfileTagButton_1.EditProfileTagButton, { besideTags: false, user: state.user }, void 0), (0, jsx_runtime_1.jsx)(EditProfilePrivateInfoButton_1.EditProfilePrivateInfoButton, { user: state.user }, void 0), Boolean(state.editingUserDefinedGroup) && ((0, jsx_runtime_1.jsx)(UserDefinedGroupUserEditButtons_1.UserDefinedGroupUserEditButtons, { userProfileId: state.user.id, userDefinedGroup: state.editingUserDefinedGroup }, void 0)), state.searchParams.lastLoginEnabled && ((0, jsx_runtime_1.jsxs)("p", __assign({ style: { fontSize: ".5em" } }, { children: ["\u6700\u7D42\u30ED\u30B0\u30A4\u30F3:", (0, util_1.isPresentDateString)(state.user.last_login) ? (0, util_1.dateToDisplay)(state.user.last_login) : undefined] }), void 0))] }, void 0)); }, [state.user, state.editingUserDefinedGroup]);
    return ((0, jsx_runtime_1.jsx)(UserBaseComponent, { user: state.user, world: state.world, worldLoading: state.worldLoading, color: state.color, main: main, besideName: besideName, footer: footer }, void 0));
}
exports.UserRaw = UserRaw;
exports.User = connect.memoFC(UserRaw);
