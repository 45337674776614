"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentsSeriesSummaries = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var React = __importStar(require("react"));
var semantic_ui_react_1 = require("semantic-ui-react");
var util_1 = require("../util");
var PagenationBySize_1 = require("./PagenationBySize");
var PagenationMenu_1 = require("./PagenationMenu");
var ContentsSeriesSummary_1 = require("./ContentsSeriesSummary");
var ContentsSeriesSummaries = function (state) {
    var contentsSeriesList = (0, PagenationBySize_1.pagenate)(state.contentsSeriesList, state.page, state.pageSize);
    if (!contentsSeriesList.length)
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}, void 0);
    var contentsSeriesIds = (0, util_1.toIdHash)(contentsSeriesList);
    var contentsItemsBySeries = {};
    for (var _i = 0, _a = state.contentsItems; _i < _a.length; _i++) {
        var contentsItem = _a[_i];
        if (contentsSeriesIds[contentsItem.contentsSeriesId]) {
            delete contentsSeriesIds[contentsItem.contentsSeriesId];
            contentsItemsBySeries[contentsItem.contentsSeriesId] = contentsItem;
        }
    }
    var _b = React.useReducer(function (prev) { return prev + 1; }, 0), reloadTweet = _b[0], incrReloadTweet = _b[1];
    React.useEffect(function () {
        twttr.widgets.load();
    }, [contentsSeriesList, reloadTweet]);
    var menuState = (0, util_1.pick)(state, PagenationMenu_1.PagePropKeys);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("p", { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, __assign({ onClick: function () { return incrReloadTweet(); } }, { children: "Reload Tweets" }), void 0) }, void 0), (0, jsx_runtime_1.jsx)(PagenationMenu_1.PagenationMenu, __assign({}, menuState, { length: state.contentsSeriesList.length }), void 0), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid, __assign({ doubling: true, stackable: true, columns: 4 }, { children: contentsSeriesList.map(function (contentsSeries) { return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(ContentsSeriesSummary_1.ContentsSeriesSummary, { contentsSeries: contentsSeries, contentsItem: contentsItemsBySeries[contentsSeries.id] }, void 0) }, contentsSeries.id)); }) }), void 0), (0, jsx_runtime_1.jsx)(PagenationMenu_1.PagenationMenu, __assign({}, menuState, { length: state.contentsSeriesList.length }), void 0)] }, void 0));
};
exports.ContentsSeriesSummaries = ContentsSeriesSummaries;
