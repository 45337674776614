"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LangSelector = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var semantic_ui_react_1 = require("semantic-ui-react");
var i18n_1 = require("../i18n");
var LangSelector = function (_a) {
    var setLang = _a.setLang;
    return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Select, { id: "langSelector", compact: true, defaultValue: (0, i18n_1.lang)(), options: i18n_1.langs.map(function (langName) { return ({
            key: langName,
            value: langName,
            text: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Flag, { name: i18n_1.countries[langName] }, void 0) }, void 0)),
        }); }), onChange: function (e, _a) {
            var value = _a.value;
            return setLang(value);
        } }, void 0));
};
exports.LangSelector = LangSelector;
