"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchInput = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var semantic_ui_react_1 = require("semantic-ui-react");
var SearchInput = function SearchInput(props) {
    return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Input, __assign({ type: "search", icon: props.value ? ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "close", size: "small", link: true, onClick: props.onChange ? function (e) { return props.onChange(e, { value: "" }); } : undefined }, void 0)) : undefined }, props), void 0));
};
exports.SearchInput = SearchInput;
