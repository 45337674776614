"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VrcGroupsPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var semantic_ui_react_1 = require("semantic-ui-react");
var react_router_1 = require("react-router");
var MainLayout_1 = require("./MainLayout");
var FirstLogin_1 = require("./FirstLogin");
var util_1 = require("../util");
var GroupSummariesPage_1 = require("./GroupSummariesPage");
var GroupDetailPage_1 = require("./GroupDetailPage");
var GroupDetailFormPage_1 = require("./GroupDetailFormPage");
var AuthenticatedRoute_1 = require("./AuthenticatedRoute");
var VrcGroupsPage = function (_a) {
    var state = _a.state;
    return ((0, jsx_runtime_1.jsx)(FirstLogin_1.FirstLogin, __assign({}, (0, util_1.pick)(state, FirstLogin_1.FirstLoginPropKeys), { children: (0, jsx_runtime_1.jsxs)(react_router_1.Switch, { children: [(0, jsx_runtime_1.jsx)(react_router_1.Route, { path: "/groups", exact: true, render: function () { return ((0, jsx_runtime_1.jsx)(MainLayout_1.MainLayout, __assign({ setLang: state.setLang, withContact: true }, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(GroupSummariesPage_1.GroupSummariesPage, __assign({}, state), void 0) }, void 0) }), void 0)); } }, void 0), (0, jsx_runtime_1.jsx)(AuthenticatedRoute_1.AuthenticatedRoute, { logined: state.logined, path: "/groups/new", exact: true, render: function () { return ((0, jsx_runtime_1.jsx)(MainLayout_1.MainLayout, __assign({ setLang: state.setLang }, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(GroupDetailFormPage_1.GroupDetailFormPage, __assign({}, state), void 0) }, void 0) }), void 0)); } }, void 0), (0, jsx_runtime_1.jsx)(react_router_1.Route, { path: "/groups/:groupId", exact: true, render: function () { return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Container, { children: (0, jsx_runtime_1.jsx)(GroupDetailPage_1.GroupDetailPage, __assign({}, state), void 0) }, void 0)); } }, void 0), (0, jsx_runtime_1.jsx)(AuthenticatedRoute_1.AuthenticatedRoute, { logined: state.logined, path: "/groups/:groupId/edit", exact: true, render: function () { return ((0, jsx_runtime_1.jsx)(MainLayout_1.MainLayout, __assign({ setLang: state.setLang }, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(GroupDetailFormPage_1.GroupDetailFormPage, __assign({}, state), void 0) }, void 0) }), void 0)); } }, void 0)] }, void 0) }), void 0));
};
exports.VrcGroupsPage = VrcGroupsPage;
