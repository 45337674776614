"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticatedRoute = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_router_dom_1 = require("react-router-dom");
var AuthenticatedRoute = function AuthenticatedRoute(state) {
    return ((0, jsx_runtime_1.jsx)(react_router_dom_1.Route, __assign({}, state, { render: function (routeProps) {
            return state.logined ? (state.render(routeProps)) : ((0, jsx_runtime_1.jsx)(react_router_dom_1.Redirect, { to: { pathname: "/login", state: { from: state.location } } }, void 0));
        } }), void 0));
};
exports.AuthenticatedRoute = AuthenticatedRoute;
