"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlatformButtons = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var semantic_ui_react_1 = require("semantic-ui-react");
var Platform_1 = require("../../enums/Platform");
var platformColors = {
    standalonewindows: "blue",
    android: "green",
};
var platformLabel = {
    standalonewindows: "PC",
    android: "Quest",
};
var PlatformButtons = function (_a) {
    var _b = _a.ignorePlatform, ignorePlatform = _b === void 0 ? {} : _b, setIgnorePlatform = _a.setIgnorePlatform;
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: Platform_1.platforms.map(function (platform) { return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, __assign({ circular: true, color: ignorePlatform[platform] ? undefined : platformColors[platform], onClick: function () {
                var _a;
                return setIgnorePlatform(__assign(__assign({}, ignorePlatform), (_a = {}, _a[platform] = !ignorePlatform[platform], _a)));
            } }, { children: platformLabel[platform] }), platform)); }) }, void 0));
};
exports.PlatformButtons = PlatformButtons;
