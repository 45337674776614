"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchNumberInput = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var SearchInput_1 = require("./SearchInput");
var SearchNumberInput = function SearchNumberInput(props) {
    return ((0, jsx_runtime_1.jsx)(SearchInput_1.SearchInput, __assign({}, props, { value: props.value == null ? "" : props.value, onChange: function (e, _a) {
            var value = _a.value;
            // eslint-disable-next-line no-restricted-globals
            return value.length ? !isNaN(value) && props.onChange(Number(value)) : props.onChange(undefined);
        } }), void 0));
};
exports.SearchNumberInput = SearchNumberInput;
