"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFirstGetGroups = void 0;
var react_1 = require("react");
function useFirstGetGroups(state) {
    (0, react_1.useEffect)(function () {
        if (!state.groupsFirstFetched && !state.groupsLoading) {
            state.setGroupsFetchRequest(true);
            state.setGroupsFirstFetched(true);
        }
    }, []);
}
exports.useFirstGetGroups = useFirstGetGroups;
