"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isJoinable = exports.isOnline = exports.permissionShortText = exports.permissionText = exports.permissionColor = exports.permissions = void 0;
exports.permissions = [
    "public",
    "friends-plus",
    "friends",
    "invite-plus",
    "invite",
    "group-public",
    "group-plus",
    "group",
    "private",
    "offline",
];
exports.permissionColor = {
    public: "blue",
    "friends-plus": "green",
    friends: "olive",
    "invite-plus": "orange",
    invite: "red",
    "group-public": "blue",
    "group-plus": "green",
    group: "olive",
    private: "brown",
    offline: "grey",
    error: "pink",
    none: "black",
};
exports.permissionText = {
    public: "public",
    "friends-plus": "friends+",
    friends: "friends",
    "invite-plus": "invite+",
    invite: "invite",
    "group-public": "group public",
    "group-plus": "group+",
    group: "group",
    private: "private",
    offline: "offline",
    error: "ERROR",
    none: "",
};
exports.permissionShortText = {
    public: "Pub",
    "friends-plus": "Fri+",
    friends: "Fri",
    "invite-plus": "Inv+",
    invite: "Inv",
    "group-public": "Grp@",
    "group-plus": "Grp+",
    group: "Grp",
    private: "Pri",
    offline: "Off",
    error: "ERR",
    none: "",
};
var onlinePermissions = {
    public: true,
    "friends-plus": true,
    friends: true,
    "invite-plus": true,
    invite: true,
    "group-public": true,
    "group-plus": true,
    group: true,
    private: true,
    offline: false,
    error: false,
    none: false,
};
function isOnline(permission) {
    return !!onlinePermissions[permission];
}
exports.isOnline = isOnline;
var joinablePermissions = {
    public: true,
    "friends-plus": true,
    friends: true,
    "invite-plus": true,
    invite: true,
    "group-public": true,
    "group-plus": true,
    group: true,
    private: false,
    offline: false,
    error: false,
    none: false,
};
function isJoinable(permission) {
    return !!joinablePermissions[permission];
}
exports.isJoinable = isJoinable;
