"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.someMinutesOrOverOneHourAgo = exports.isLangEqual = exports.setLang = exports.lang = exports.defaultLang = void 0;
var locales_1 = require("./locales");
__exportStar(require("./locales"), exports);
exports.defaultLang = (function () {
    var currentLang = (navigator.browserLanguage ||
        navigator.language ||
        navigator.userLanguage ||
        "ja").substr(0, 2);
    return locales_1.locales[currentLang] ? currentLang : "en";
})();
var state = {
    lang: exports.defaultLang,
};
var t = {};
for (var _i = 0, _a = Object.keys(locales_1.locales); _i < _a.length; _i++) {
    var lang_1 = _a[_i];
    var _loop_1 = function (key) {
        if (Object.getOwnPropertyDescriptor(t, key))
            return "continue";
        Object.defineProperty(t, key, {
            get: function () {
                return locales_1.locales[state.lang][key];
            },
        });
    };
    for (var _b = 0, _c = Object.keys(locales_1.locales[lang_1]); _b < _c.length; _b++) {
        var key = _c[_b];
        _loop_1(key);
    }
}
exports.default = t;
// export const T = (key: I18nKey) => langs[state.lang][key];
var lang = function () { return state.lang; };
exports.lang = lang;
var setLang = function (nextLang) { return (state.lang = nextLang); };
exports.setLang = setLang;
var isLangEqual = function (prev, next) { return prev.lang === next.lang; };
exports.isLangEqual = isLangEqual;
function someMinutesOrOverOneHourAgo(minutes) {
    return minutes >= 60 ? t.overOneHourAgo : minutes === 1 ? "1 " + t.minuteAgo : minutes + " " + t.minutesAgo;
}
exports.someMinutesOrOverOneHourAgo = someMinutesOrOverOneHourAgo;
