"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetGroups = void 0;
var react_1 = require("react");
var api_1 = require("../api");
var sentry_1 = require("../sentry");
function useGetGroups(state) {
    (0, react_1.useEffect)(function () {
        if (state.groupsFetchRequest && !state.groupsLoading) {
            state.setGroupsFetchRequest(false);
            state.setGroupsLoading(true);
            (0, api_1.fetchGroups)().then(function (groups) {
                state.setGroups(groups);
                state.setGroupsLoading(false);
            }, function (error) {
                (0, sentry_1.captureException)(error);
                state.setGroupsLoading(false);
            });
        }
    }, [state.groupsFetchRequest]);
}
exports.useGetGroups = useGetGroups;
