"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReturnToTop = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var style = {
    position: "fixed",
    right: "10px",
    bottom: "10px",
    opacity: "0.5",
};
var ReturnToTop = function () {
    var onClick = (0, react_1.useCallback)(function () {
        window.scrollTo(0, 0);
    }, []);
    return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, __assign({ icon: true, style: style, color: "teal", onClick: onClick }, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "arrow up" }, void 0) }), void 0));
};
exports.ReturnToTop = ReturnToTop;
